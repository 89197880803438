import { Component, OnInit } from '@angular/core';
import { infoData } from './../../../shared/infoData';
import { TranslateService } from '@ngx-translate/core';
import { AshalService } from '../../../shared/ashal.service';

@Component({
  selector: 'app-voucher-report',
  templateUrl: './voucher-report.component.html',
  styleUrls: ['./voucher-report.component.css']
})
export class VoucherReportComponent implements OnInit {
  table_data: any = [];
  infoData: infoData;
  loading: boolean = false;
  getheader: boolean = false;




  constructor(
    private translate: TranslateService,
    private ashalService: AshalService,


  ) {
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
    this.loading = true;
    this.ashalService.getinfoData().subscribe(
      res => {
        if (res) {
          this.infoData = res;
          this.getheader = true;
           this.loading = false;
        }
      }
    );
    this.ashalService.getinfoData().subscribe(
      res => {
        if (res) {
          this.infoData = res;
          this.getheader = true;
          this.loading = false;
        }
      }
    );
  }

  ngOnInit() {
  }
  vauchersReport() {
    this.loading = true;
    return this.ashalService.getvauchersReport().subscribe(
      res => {
        if (res) {
          this.table_data = res
          this.loading = false;

        }
      }
    );
  }
  print(): void {
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
}

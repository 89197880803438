import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { Invoice } from '../../../shared/invoice';
import { ActivatedRoute } from '@angular/router';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { infoData } from './../../../shared/infoData';


@Component({
  selector: 'app-invoice',
  templateUrl: './inv-report.component.html',
  styleUrls: ['./inv-report.component.css']
})
export class InvReportComponent implements OnInit {

  form: FormGroup;
  save: string;
  invoice: Invoice = {};
  id: any;
  infoData: infoData;
  getheader: boolean = false;


  // invoice defaults
  accdefs: object;
  cashAccdefs: object;
  currencys: object; // العملات
  csts: object; // مركز التكلفة
  mndobs: object; // المندوبين
  items: object; // الأصناف
  stores: object; // المستودعات

  InvHed_ID: number;

  
  // get units based on selected item
  units: any[] = [];
  itemsRows: any[] = [];
  is_arabic:boolean = false;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    
    private route: ActivatedRoute
  ) {

    if(sessionStorage.getItem("langs") == "0")
    {this.is_arabic = true;}
    else
    {this.is_arabic = false;}
    
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
  
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // do something
      if(params.lang == 'ar'){
       this.is_arabic = true;
      }
      else{
       this.is_arabic = false;
      }
     //  alert(this.is_arabic);
      
 });
    this.route.params.subscribe((params: { id: any; }) => { this.id = params.id; });
    this.getInvoice();
    
/*     let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes; */
    // alert(`${monthstr}-${daystr}-${year}, ${monthstr}/${daystr}/${year}.`);
    this.form = fb.group({
      opeType: [],
      InvHed_ID : [],
      InvNo : [],
      HDat : [,Validators.required],
      GDat : [,Validators.required],
      InvCashPay : [0],
      CusVenNo : [""],
      CusVenNm : [""],
      accdefActive : [''],
      CashAcc : [1020001],
      CusVenTel : [],
      CusVenAdd : [],
      CurTyp : [1],
      RefNo : [],
      MndNo : [1, Validators.required],
      Remark : [],
      Vat_No : [],
      CustPri : [0],
      InvCstNo : [1],
      InvCstNo2 : [1],
      CashPay : [0],
      InvTot : [0],
      InvQty : [0],
      InvDisVal : [0],
      Commission : [0],
      InvDisPrs : [0],
      ShippingRates : [0],
      InvNet : [0],
      ShipExpens : [0],
      InvWight_T : [0],
      TaxTot : [0],
      details: fb.array([]), // details
    });
    this.ashalService.getinfoData().subscribe(
      res => {
        if (res) {
          this.infoData = res;
          this.getheader = true;
          // this.loading = false;
        }
      }
    );
  }

  get detailsForm(){ return this.form.get('details') as FormArray }

  
  
  getInvoice(){
    this.ashalService.printInvoice(this.id).subscribe(
      invoice => {
        this.invoice = invoice;
      }
    );
  }

 

// get item desc when item changed  
  itemChanged(ItmNo: string, i:number){
    this.ashalService.getItemData(ItmNo).subscribe(
      itemData => {
        this.itemsRows[i] = itemData;
        this.units[i] = itemData.units;
        this.detailsForm.controls[i].patchValue({ItmDes: ItmNo});
        this.detailsForm.controls[i].patchValue({ItmNo: ItmNo});
        this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(this.units[i][parseInt(itemData.DefultUnit)].ItmUntE);
        this.detailsForm.controls[i]['controls']['Tax'].patchValue(itemData.tax);
        this.calculateAmount(i);
        this.getInvoiceTotal();
      }
    );
  }

  FillUnits(ItmNo: string, i:number,UnitDef:string){
    this.ashalService.getItemData(ItmNo).subscribe(
      itemData => {
        this.itemsRows[i] = itemData;
        this.units[i] = itemData.units;
        if(UnitDef == "")
        {
          this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(this.units[i]);
        }
        else
        {
          this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(UnitDef);
        }
      }
    );
  }


 
  calculateAmount(i){
    var amountBeforeDiscount = this.detailsForm.controls[i]['controls']['Price'].value * this.detailsForm.controls[i]['controls']['Qty'].value;
    var discount = this.detailsForm.controls[i]['controls']['ItmDis'].value == 0 ? 0 : ((this.detailsForm.controls[i]['controls']['ItmDis'].value ) / 100) * (amountBeforeDiscount);
    var amountAfterDiscount = amountBeforeDiscount - discount;
    var tax = (this.detailsForm.controls[i]['controls']['Tax'].value / 100) * amountAfterDiscount;
    var amountAfterDiscount_Tax = amountAfterDiscount + tax;    
    this.detailsForm.controls[i]['controls']['Amount'].patchValue(amountAfterDiscount_Tax.toFixed(2));
    
    // udpate invoice amount total and qty total
    this.getInvoiceTotal();

  }

  getInvoiceTotal(){
    var invTotal: number = 0;
    var taxTotal: number = 0;
    for (let i = 0; i < this.detailsForm.length; i++) { 
      invTotal += ((Number(this.detailsForm.controls[i]['controls']['Amount'].value) * 100) / (Number(this.detailsForm.controls[i]['controls']['Tax'].value) + 100));      
      taxTotal += Number(this.detailsForm.controls[i]['controls']['Amount'].value) - ((Number(this.detailsForm.controls[i]['controls']['Amount'].value) * 100) / (Number(this.detailsForm.controls[i]['controls']['Tax'].value) + 100));      
    }
    this.form.controls['InvTot'].patchValue(invTotal.toFixed(2));
    this.form.controls['TaxTot'].patchValue(taxTotal.toFixed(2));
    this.getNetTotal();

    // get qty total
    var InvQty: number = 0;
    for (let i = 0; i < this.detailsForm.length; i++) { 
      InvQty += Number(this.detailsForm.controls[i]['controls']['Qty'].value);
    }
    this.form.controls['InvQty'].patchValue(InvQty.toFixed(2));
  }


  getNetTotal(){
    var Commission = parseFloat(this.form.controls['Commission'].value);
    var ShippingRates = parseFloat(this.form.controls['ShippingRates'].value);
    var ShipExpens = parseFloat(this.form.controls['ShipExpens'].value);
    var TaxTot = parseFloat(this.form.controls['TaxTot'].value);
    var invTotal = parseFloat(this.form.controls['InvTot'].value);

//    for (let i = 0; i < this.detailsForm.length; i++) { 
//     invTotal += Number(this.detailsForm.controls[i]['controls']['Amount'].value);
//    }

    this.form.controls['InvNet'].setValue(((invTotal) + (Commission + ShippingRates + ShipExpens + TaxTot)).toFixed(2));

  }


  
 
  ngOnInit() {
    this.save = this.id == 0 ? 'save' : 'update';
    if(this.id !=0)this.form.controls['InvNo'].disable();
  }


print(): void {
  let printContents, popupWin;
  printContents = document.getElementById('print-section').innerHTML;
  popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  popupWin.document.open();
  popupWin.document.write(`
    <html>
      <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
      </head>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`
  );
  popupWin.document.close();
}

}


import { Component, OnInit } from '@angular/core';
// import { CategoryReportComponent } from './category-report.component';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {TranslateService} from "@ngx-translate/core";
import {ToastrService} from "ngx-toastr";
import {AshalService} from "../../../shared/ashal.service";
import { infoData } from 'src/app/shared/infoData';



@Component({
  selector: 'app-category-report',
  templateUrl: './category-report.component.html',
  styleUrls: ['./category-report.component.css']
})
export class CategoryReportComponent implements OnInit {
  angForm: FormGroup;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder
  ) {
    this.ashalService.check_is_login();
    this.createForm();
    this.translate.setDefaultLang('en');
  }

  // constructor() { }

  ngOnInit() {
    this.infoData();
  }

  infoData() {
    // return this.ashalService.infoData();
  }
  createForm() {
    this.angForm = this.fb.group({
      name: ['', Validators.required ]
    });
  }
}

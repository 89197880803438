
import { Component, OnInit } from '@angular/core';
import { infoData } from '../../../shared/infoData';
import { Voucher } from '../../../shared/voucher';
import { AshalService } from 'src/app/shared/ashal.service';

@Component({
  selector: 'app-print-layout',
  templateUrl: './print-layout.component.html',
  styleUrls: ['./print-layout.component.css']
})
export class PrintLayoutComponent implements OnInit {

  infoData:infoData;
  getheader:boolean = false;
  constructor(
    private ashalService: AshalService
  ) { 
    this.ashalService.check_is_login();
    this.ashalService.getinfoData().subscribe(
      res=>{
        if(res){
          this.infoData = res;
          this.getheader = true;
          // this.loading = false;
        }
      }
    );
  }


  ngOnInit() {
  }

}

import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';

import { AshalService } from '../../../shared/ashal.service';
import { NewCategoryComponent } from '../new-category/new-category.component';
import { Category } from 'src/app/shared/category';

import { PrintService } from "../../../print.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css']
})
export class CategoryListComponent implements OnInit {

  dataSource = new MatTableDataSource();
  print_premisions: boolean = false;
  add_premisions: boolean = false;
  edit_premisions: boolean = false;
  delete_premisions: boolean = false;
  categorys: Category[];
  listData: any;
  serial: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['index', 'Eng_Des', 'Arb_Des', 'CAT_No'];

  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    private dialog: MatDialog,
    public printService: PrintService
  ) {
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
  }

  ngOnInit() {
    this.print_premisions =  this.ashalService.is_premision("FilStr_premisions","cat_print",true); 
    this.add_premisions =  this.ashalService.is_premision("FilStr_premisions","cat_add",true);
    this.edit_premisions =  this.ashalService.is_premision("FilStr_premisions","cat_edit",true);
    this.delete_premisions =  this.ashalService.is_premision("FilStr_premisions","cat_delete",true);
    this.getCategorys();
  }

  getCategorys() {
    return this.ashalService.getCategorys().subscribe(
      res => {
        if (res) {
          this.dataSource.data = res
          this.listData = this.dataSource
          this.categorys = res
        }
      }
    );
  }

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

  onCreateNew() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    const dialogCallBack = this.dialog.open(NewCategoryComponent, dialogConfig);
    dialogCallBack.afterClosed().subscribe(() => {
      this.getCategorys();
    });
  }

  onEdit(Unit_No: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    dialogConfig.data = Unit_No;
    const dialogCallBack = this.dialog.open(NewCategoryComponent, dialogConfig);
    dialogCallBack.afterClosed().subscribe(() => {
      this.getCategorys();
    });
  }

  onDelete(CAT_No: string) {
    if (confirm('هل تريد بتأكيد حذف السجيل ؟  \n Do you want to confirm deletion of the record?')) {
      this.ashalService.deleteCategory(CAT_No)
      .subscribe(
        res => {
          if(res)
          {
            this.tostr.success('تم حذف السجل بنجاح  \n The record was deleted successfully');
            this.getCategorys();
          }
          else
          {
            alert('عفواً ... لايمكن حذف السجل لأنه مستخدم  \n this record cant be deleted, aleady used');
          }
        },
        err => console.error(err)
      )
    } else {
      // Do nothing!
    }
  }

  onPrintCategorys() {
    this.printService
      .printDocument('category');
  }

}


import { Component, OnInit } from '@angular/core';
import { PrintService } from '../print.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-unit',
  templateUrl: './unit.component.html',
  styleUrls: ['./unit.component.css']
})
export class UnitComponent implements OnInit {

  constructor(route: ActivatedRoute,private printService: PrintService) { }

  ngOnInit() {
  }

}

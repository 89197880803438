import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { AshalService } from '../../../shared/ashal.service';
import { Invoice } from 'src/app/shared/invoice';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.css']
})
export class InvoicesListComponent implements OnInit {

  dataSource = new MatTableDataSource();

  print_premisions: boolean = false;
  add_premisions: boolean = false;
  edit_premisions: boolean = false;
  delete_premisions: boolean = false;

  invoices: Invoice[];
  listData: any;
  serial: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['index', 'InvNo', 'CusVenNm', 'GDat', 'InvNet',  'InvHed_ID'];

  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    private dialog: MatDialog,
  ) { 
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');}

  ngOnInit() {
    this.print_premisions =  this.ashalService.is_premision("InvStr_premisions","sales_print",true); 
    this.add_premisions =  this.ashalService.is_premision("InvStr_premisions","sales_add",true);
    this.edit_premisions =  this.ashalService.is_premision("InvStr_premisions","sales_edit",true);
    this.delete_premisions =  this.ashalService.is_premision("InvStr_premisions","sales_delete",true);
    this.getInvoices();
  }

  getInvoices(){
    return this.ashalService.getInvoices().subscribe(
      res => {
        if(res){
          this.dataSource.data = res
          this.listData = this.dataSource
          this.invoices = res
        }
      }
    );
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  applyFilter(filterValue: string){
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

  onDelete(Inv_Id: string){
    if (confirm('هل تريد بتأكيد حذف السجيل ؟  \n Do you want to confirm deletion of the record?')) {
      this.ashalService.deleteInvoice(Inv_Id)
      .subscribe(
      res => {
        if(res)
        {
          console.error(res);
          this.tostr.success('تم حذف السجل بنجاح  \n The record was deleted successfully');
          this.getInvoices();
        }
        else
        {
          alert('عفواً ... لايمكن حذف السجل لأنه مستخدم  \n this record cant be deleted, aleady used');
        }
      },
      err => console.error(err)
    )
    } else {
      // Do nothing!
    }
  }

}

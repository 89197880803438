import { Component, OnInit } from '@angular/core';
// import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { infoData } from './../../../shared/infoData';

@Component({
  selector: 'app-inv-search',
  templateUrl: './inv-search.component.html',
  styleUrls: ['./inv-search.component.css']
})
export class InvSearchComponent implements OnInit {
  table_data: any =[];
  fromDate: any ="2018-12-01" ;
  toDate: any ="2019-12-01" ;
  infoData: infoData;
  getheader: boolean = false;
  loading: boolean = false;
  is_arabic:boolean = true;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService) 
    { 
      this.ashalService.check_is_login();
      this.translate.setDefaultLang('en');
     
      if(sessionStorage.getItem("langs") == "0")
      {this.is_arabic = true;}
      else
      {this.is_arabic = false;}
      
      this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
        // do something
        if(params.lang == 'ar'){
         this.is_arabic = true;
        }
        else{
         this.is_arabic = false;
        }
      });

      this.ashalService.getinfoData().subscribe(
        res => {
          if (res) {
            this.infoData = res;
            this.getheader = true;
            this.loading = false;
          }
        }
      );

    }

  ngOnInit() {

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes;

    this.fromDate = today.getFullYear()+"-"+"01"+"-"+"01";
    this.toDate = year+"-"+monthstr+"-"+daystr;

  }

  getInvoices(){
    this.table_data = [];
    this.loading = true;
    return this.ashalService.getInvoices2(this.fromDate.replace(/-/g,"/"),this.toDate.replace(/-/g,"/")).subscribe(
      res => {
        if(res){
          this.table_data = res
          this.loading = false;
        }
      }
    );

  }

  print(): void {
    this.loading = true;
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

}

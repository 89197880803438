import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { SideNavComponent } from './dashboard/side-nav/side-nav.component';
import { UnitsListComponent } from './dashboard/units/units-list/units-list.component';
import { NewItemComponent } from './dashboard/items/new-item/new-item.component';
import { CategoryReportComponent } from './dashboard/catogory/category-report/category-report.component';
import { CategoryListComponent } from './dashboard/catogory/category-list/category-list.component';
import { AccdefListComponent } from './dashboard/accdef/accdef-list/accdef-list.component';
import { ItemsListComponent } from './dashboard/items/items-list/items-list.component';
import { VoucherComponent } from './dashboard/vouchers/voucher/voucher.component';
import { InvoiceComponent } from './dashboard/invoices/invoice/invoice.component';
import { ItemComponent } from './dashboard/items/item/item.component';
import { VouchersListComponent } from './dashboard/vouchers/vouchers-list/vouchers-list.component';
import { ReportComponent } from './dashboard/reports/report/report.component';
import { InvoicesListComponent } from './dashboard/invoices/invoices-list/invoices-list.component';
import { PrintLayoutComponent } from './dashboard/print/print-layout/print-layout.component';
import { StatementComponent } from './dashboard/statement/statement.component';
import { InvSearchComponent } from './dashboard/invoices/inv-search/inv-search.component';
import { InvReportComponent } from './dashboard/invoices/inv-report/inv-report.component';
import { VoucherReportComponent } from './dashboard/vouchers/voucher-report/voucher-report.component';
import { VoucherPrintComponent } from './dashboard/vouchers/voucher-print/voucher-print.component';
import { UnitComponent } from './unit/unit.component';
import { UserListComponent } from './dashboard/users/user-list/user-list.component';
import { NewUserComponent } from './dashboard/users/new-user/new-user.component'
import {TranslateModule} from '@ngx-translate/core';


const routes: Routes = [
  { 
    path: "dashboard", component: SideNavComponent,
    children: [
      { path: "units-list", component: UnitsListComponent },
      { path: "categorys-list", component: CategoryListComponent },
      { path: "accdef-list", component: AccdefListComponent },
      { path: "items-list", component: ItemsListComponent },
      { path: "new-item", component: NewItemComponent },
      { path: "item/:id", component: ItemComponent },
      { path: "vouchers-list", component: VouchersListComponent },
      { path: "voucher/:id", component: VoucherComponent },
      { path: "invoice/:id", component: InvoiceComponent },
      { path: "invoices-list", component: InvoicesListComponent },
      { path: "report", component: ReportComponent },
      { path: "statement", component: StatementComponent },
      { path: "voucher-report", component: VoucherReportComponent },
      { path: "inv-search", component: InvSearchComponent },
      { path: "user", component: UserListComponent },
      { path: "inv-report/:id", component: InvReportComponent },
      { path: "voucher-print/:id", component: VoucherPrintComponent },
      { path: "categorys-report", component: CategoryReportComponent },

    ],
  },

  { path: 'print',
    outlet: 'print',
    component: PrintLayoutComponent,
    children: [
      { path: 'unit', component: UnitsListComponent },
      { path: 'category', component: CategoryListComponent },
      { path: 'Item', component: ItemsListComponent },
      { path: 'user', component: UserListComponent },
      { path: 'accdef', component: AccdefListComponent }
    ]
  }



    // {path: "units", component: UnitsComponent},
    
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    TranslateModule
  ],
  exports: [
    RouterModule,
    TranslateModule
  ]
})
export class AppRoutingModule { }

import { Component, OnInit, Inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { AccDef } from 'src/app/shared/accdef';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';

@Component({
  selector: 'app-new-accdef',
  templateUrl: './new-accdef.component.html',
  styleUrls: ['./new-accdef.component.css']
})
export class NewAccdefComponent implements OnInit {
  AccList: any;
  AccCatList: any;
  is_arabic:boolean = true;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<NewAccdefComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if(sessionStorage.getItem("langs") == "0")
      {this.is_arabic = true;}
      else
      {this.is_arabic = false;}
      this.ashalService.check_is_login();
      this.translate.setDefaultLang('en');
      this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
        // do something
        if(params.lang == 'ar'){
         this.is_arabic = true;
        }
        else{
         this.is_arabic = false;
        }
      });

      this.ashalService.getAccDefPer().subscribe(
        res => {
          if (res) {
            this.AccList = res;
          }
        }
      );

      this.ashalService.getAccCat().subscribe(
        res => {
          if (res) {
            this.AccCatList = res;
          }
        }
      );
  }

  newForm = this.fb.group({

    AccDef_No : ['1', Validators.required],
    ParAcc : ['1', Validators.required],
    Eng_Des : ['', Validators.required],
    Arb_Des : ['', Validators.required],
    AccCat: ['1', Validators.required],
    Lev: ['0',Validators.required],
    Sts: ['0',Validators.required],
    DC: ['0',Validators.required],
    Trn: ['0',Validators.required]

  });

  ngOnInit() {
    this.newForm.controls['AccDef_No'].disable();
    if(this.data)this.setValues();
  }

  
  // set form values if edit
  private setValues(){

    this.ashalService.viewAccdef(this.data).subscribe(
      res => {
        this.newForm.patchValue({
          AccDef_No : res.AccDef_No,
          ParAcc : res.ParAcc,
          Eng_Des: res.Eng_Des,
          Arb_Des: res.Arb_Des,
          AccCat: res.AccCat,
          Lev: res.Lev,
          Sts: res.Sts,
          DC: res.DC,
          Trn: res.Trn
          
        });
        this.newForm.controls['ParAcc'].disable();
      },
      err => console.error(err)
    )

  }


  // Using getters will make your code look pretty
  get AccDef_No() { return this.newForm.get('AccDef_No') }
  get ParAcc() { return this.newForm.get('ParAcc') }
  get Eng_Des() { return this.newForm.get('Eng_Des') }
  get Arb_Des() { return this.newForm.get('Arb_Des') }
  get AccCat() { return this.newForm.get('AccCat') }
  get Lev() { return this.newForm.get('Lev') }
  get Sts() { return this.newForm.get('Sts') }
  get DC() { return this.newForm.get('DC') }
  get Trn() { return this.newForm.get('Trn') }


  // new or update accdef
  public newAccdef() {

    if(!this.newForm.valid) return null;
    const vals = this.newForm.value;
    
    const accdef: AccDef = {
      AccDef_No: this.AccDef_No.value,
      ParAcc: this.ParAcc.value,
      Eng_Des: this.Eng_Des.value,
      Arb_Des: this.Arb_Des.value,
      AccCat: this.AccCat.value,
      Lev: this.Lev.value,
      Sts: this.Sts.value,
      DC: this.DC.value,
      Trn: this.Trn.value

    }


    // if update
    if(this.data){
      this.ashalService.updateAccdef(this.data, accdef)
      .subscribe(
        res => {
          //console.log(res);
          this.dialogRef.close();
          this.tostr.success('تم تحديث الحساب بنجاح \n Account updated');
        },
        err => console.error(err)
      )
    }

    // if new
    else{
      this.ashalService.newAccDef(accdef)
      .subscribe(
        res => {
          //console.log(res);
          this.dialogRef.close();
          this.tostr.success('تم تسجيل الحساب بنجاح \n New Account Created');
        },
        err => console.error(err)
      )
    }


  }

  ParaccChanged(ParaccNo: string){
    this.ashalService.viewAccdef(ParaccNo).subscribe(
      res => {
        this.newForm.controls['Lev'].setValue(res.Lev - 10);
        this.newForm.controls['AccCat'].setValue(res.AccCat);
        this.newForm.controls['DC'].setValue(res.DC);
        this.newForm.controls['Trn'].setValue(res.Trn);

      }
    );
  }

  // close popup form
  onClose(){
    // this.newBreadwinnerForm.reset();
    this.dialogRef.close();
  }

}
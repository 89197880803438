import { Voucher } from './../../shared/voucher';

import { infoData } from './../../shared/infoData';
import { Component, OnInit,Injectable } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../shared/ashal.service';
import { TranslateService,LangChangeEvent } from '@ngx-translate/core';
import {
  NgbDateStruct, NgbCalendar, NgbCalendarIslamicCivil, NgbDatepickerI18n
} from '@ng-bootstrap/ng-bootstrap';
declare const tafqeet: any;

const WEEKDAYS = ['ن', 'ث', 'ر', 'خ', 'ج', 'س', 'ح'];
const MONTHS = ['محرم', 'صفر', 'ربيع الأول', 'ربيع الآخر', 'جمادى الأولى', 'جمادى الآخرة', 'رجب', 'شعبان', 'رمضان', 'شوال',
  'ذو القعدة', 'ذو الحجة'];
@Injectable()

export class IslamicI18n extends NgbDatepickerI18n {

  getWeekdayShortName(weekday: number) {
    return WEEKDAYS[weekday - 1];
  }

  getMonthShortName(month: number) {
    return MONTHS[month - 1];
  }

  getMonthFullName(month: number) {
    return MONTHS[month - 1];
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }
}

@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css'],
 
})
export class StatementComponent implements OnInit {
  // angForm: FormGroup;
  version: string = window['ngSelectVersion'];
  table_data: any = [];
  today = new Date();
  fromDate: any ;
  toDate: any ;
  fromAcc: any;
  toAcc: any;
  infoData: infoData;
  AccList: any;
  getheader: boolean = false;
  loading: boolean = false;
  sum: any = 0;
  sum_tafget:string = "";
  a: any ;
  b: any ;
  model: NgbDateStruct;
  is_arabic:boolean = false;
  accdefs: object;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    private calendar: NgbCalendar
  ) {
    
    this.ashalService.check_is_login();
    // this.createForm();
    this.translate.setDefaultLang('en');
    // this.cur_lang = this.translate.use();
    if(sessionStorage.getItem("langs") == "0")
    {this.is_arabic = true;}
    else
    {this.is_arabic = false;}
    
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
           // do something
           if(params.lang == 'ar'){
            this.is_arabic = true;
           }
           else{
            this.is_arabic = false;
           }
          //  alert(this.is_arabic);
           
      });
    this.translate.onDefaultLangChange.subscribe((params: LangChangeEvent) => {
           // do something
           if(params.lang == 'ar'){
            this.is_arabic = true;
           }
           else{
            this.is_arabic = false;
           }
          //  alert(this.is_arabic);
      }
      );

    this.ashalService.getinfoData().subscribe(
      res => {
        if (res) {
          this.infoData = res;
          this.getheader = true;
          // this.loading = false;
        }
      }
    );
/*     this.ashalService.getAccDef().subscribe(
      res => {
        if (res) {
          this.AccList = res;
          this.loading = false;
        }
      }
    ); */
    this.preVoucher();
  }

  ngOnInit() {

    let year = this.today.getFullYear();
    let month = this.today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = this.today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = this.today.getHours();
    let minutes = this.today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes;

    this.fromDate = this.today.getFullYear()+"-"+"01"+"-"+"01";
    this.toDate = year+"-"+monthstr+"-"+daystr;
  }


  ///

  preVoucher(){
    this.ashalService.preVoucher().subscribe(

      preVoucher => {
        this.accdefs = preVoucher.accdefs;
      }

    );
  }


  getInvoices() {
    this.loading = true;
    this.sum = 0.00;
    let fromDate = this.fromDate;
    let toDate = this.toDate;
    return this.ashalService.getVouchers2(fromDate.replace(/-/g,"/"), toDate.replace(/-/g,"/"), this.fromAcc, this.toAcc).subscribe(
      res => {
        if (res) {
          // this.dataSource.data = res
          // this.listData = this.dataSource
          //console.log(res);
          this.table_data = res
          this.table_data.forEach(item => {
            this.sum = this.sum + parseFloat(item.BALANCE);
            item.BALANCE = this.sum;

          });
          this.sum_tafget = tafqeet(this.sum);
          this.loading = false;

        }
      }
    );
  }

  print(): void {
    this.loading = true;
    let printContents, popupWin;
    printContents = document.getElementById('print-section').innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
        <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }

  

}

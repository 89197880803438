import { Component, OnInit, Inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { User } from 'src/app/shared/user';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class NewUserComponent implements OnInit {

  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<NewUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      this.ashalService.check_is_login();
      translate.setDefaultLang('en');
  }

  newForm = this.fb.group({

    UsrNamE : ['', Validators.required],
    UsrNamA : ['', Validators.required],
    Pass : ['', Validators.required],
    Pass1 : ['', Validators.required],
    ProLng : ['0'],
    Sts : ['0'],
    FilStr:['0'],
    unit : ['0'],
    unit_add : ['0'],
    unit_edit : ['0'],
    unit_delete : ['0'],
    unit_print : ['0'],
    cat : ['0'],
    cat_add : ['0'],
    cat_edit : ['0'],
    cat_delete : ['0'],
    cat_print : ['0'],
    item : ['0'],
    item_add : ['0'],
    item_edit : ['0'],
    item_delete : ['0'],
    item_print : ['0'],
    accdef : ['0'],
    accdef_add : ['0'],
    accdef_edit : ['0'],
    accdef_delete : ['0'],
    accdef_print : ['0'],
    InvStr:['0'],
    invoice : ['0'],
    invoice_add : ['0'],
    invoice_edit : ['0'],
    invoice_delete : ['0'],
    invoice_print : ['0'],
    SndStr:['0'],
    voucher : ['0'],
    voucher_add : ['0'],
    voucher_edit : ['0'],
    voucher_delete : ['0'],
    voucher_print : ['0'],
    AccRep:['0'],
    reports:['0'],
    invrep:['0'],
    statement:['0'],
    SetStr:['0'],
    users : ['0'],
    users_edit : ['0'],
    users_print : ['0'],


  });

  ngOnInit() {
    //console.log(this.data);
    if(this.data)this.setValues();
  }

  // set form values if edit
  private setValues(){

    this.ashalService.viewUser(this.data).subscribe(
      res => {
        //console.log(res.FilStr);
        this.newForm.patchValue({
          UsrNamE: res.UsrNamE,
          UsrNamA: res.UsrNamA,
          Pass: res.Pass,
          Pass1: res.Pass,
          ProLng: res.ProLng,
          Sts: res.Sts,
          unit: Number(res.FilStr.substr(0,1)),
          unit_add : Number(res.FilStr.substr(1,1)),
          unit_edit : Number(res.FilStr.substr(2,1)),
          unit_delete : Number(res.FilStr.substr(3,1)),
          unit_print : Number(res.FilStr.substr(4,1)),
          cat : Number(res.FilStr.substr(5,1)),
          cat_add : Number(res.FilStr.substr(6,1)),
          cat_edit : Number(res.FilStr.substr(7,1)),
          cat_delete : Number(res.FilStr.substr(8,1)),
          cat_print : Number(res.FilStr.substr(9,1)),
          item : Number(res.FilStr.substr(10,1)),
          item_add : Number(res.FilStr.substr(11,1)),
          item_edit : Number(res.FilStr.substr(12,1)),
          item_delete : Number(res.FilStr.substr(13,1)),
          item_print : Number(res.FilStr.substr(14,1)),
          accdef : Number(res.FilStr.substr(15,1)),
          accdef_add : Number(res.FilStr.substr(16,1)),
          accdef_edit : Number(res.FilStr.substr(17,1)),
          accdef_delete : Number(res.FilStr.substr(18,1)),
          accdef_print : Number(res.FilStr.substr(19,1)),
          invoice : Number(res.InvStr.substr(0,1)),
          invoice_add : Number(res.InvStr.substr(1,1)),
          invoice_edit : Number(res.InvStr.substr(2,1)),
          invoice_delete : Number(res.InvStr.substr(3,1)),
          invoice_print : Number(res.InvStr.substr(4,1)),
          voucher : Number(res.SndStr.substr(0,1)),
          voucher_add : Number(res.SndStr.substr(1,1)),
          voucher_edit : Number(res.SndStr.substr(2,1)),
          voucher_delete : Number(res.SndStr.substr(3,1)),
          voucher_print : Number(res.SndStr.substr(4,1)),
          reports: Number(res.AccRep.substr(0,1)),
          invrep:Number(res.AccRep.substr(1,1)),
          statement:Number(res.AccRep.substr(2,1)),
          users : Number(res.SetStr.substr(0,1)),
          users_edit : Number(res.SetStr.substr(1,1)),
          users_print : Number(res.SetStr.substr(2,1))

        });
      },
      err => console.error(err)
    )

  }


  // Using getters will make your code look pretty
  get UsrNamA() { return this.newForm.get('UsrNamA') }
  get UsrNamE() { return this.newForm.get('UsrNamE') }
  get Pass() { return this.newForm.get('Pass') }
  get Pass1() { return this.newForm.get('Pass1') }
  get ProLng() { return this.newForm.get('ProLng') }
  get Sts() { return this.newForm.get('Sts') }



  // add new unit
  public newUser() {
    if(this.newForm.controls['Pass'].value != this.newForm.controls['Pass1'].value )
    {
      alert('عفواً ... كلمة المرور غير متطابقة \n Sorry ... Password does not match');
      return  ;
    }
    if(!this.newForm.valid) return null;
    const vals = this.newForm.value;
    var fil_str = (this.newForm.controls["unit"].value + "" + this.newForm.controls["unit_add"].value + this.newForm.controls["unit_edit"].value + this.newForm.controls["unit_delete"].value + this.newForm.controls["unit_print"].value
    + this.newForm.controls["cat"].value + "" + this.newForm.controls["cat_add"].value + this.newForm.controls["cat_edit"].value + this.newForm.controls["cat_delete"].value + this.newForm.controls["cat_print"].value
    + this.newForm.controls["item"].value + "" + this.newForm.controls["item_add"].value + this.newForm.controls["item_edit"].value + this.newForm.controls["item_delete"].value + this.newForm.controls["item_print"].value
    + this.newForm.controls["accdef"].value + "" + this.newForm.controls["accdef_add"].value + this.newForm.controls["accdef_edit"].value + this.newForm.controls["accdef_delete"].value + this.newForm.controls["accdef_print"].value);
    fil_str = fil_str.replace(/true/g,"1");
    fil_str = fil_str.replace(/false/g,"0");
    var inv_str = this.newForm.controls["invoice"].value + "" + this.newForm.controls["invoice_add"].value + this.newForm.controls["invoice_edit"].value + this.newForm.controls["invoice_delete"].value + this.newForm.controls["invoice_print"].value;
    var snd_str = this.newForm.controls["voucher"].value + "" + this.newForm.controls["voucher_add"].value + this.newForm.controls["voucher_edit"].value + this.newForm.controls["voucher_delete"].value + this.newForm.controls["voucher_print"].value;
    inv_str = inv_str.replace(/true/g,"1");
    inv_str = inv_str.replace(/false/g,"0");
    snd_str = snd_str.replace(/true/g,"1");
    snd_str = snd_str.replace(/false/g,"0");
    var acc_rep = this.newForm.controls["reports"].value + "" + this.newForm.controls["invrep"].value + this.newForm.controls["statement"].value ;
    var set_str = this.newForm.controls["users"].value + "" + this.newForm.controls["users_edit"].value + this.newForm.controls["users_print"].value;
    acc_rep = acc_rep.replace(/true/g,"1");
    acc_rep = acc_rep.replace(/false/g,"0");
    set_str = set_str.replace(/true/g,"1");
    set_str = set_str.replace(/false/g,"0");
    const user: User = {
      UsrNamA: this.UsrNamA.value,
      UsrNamE: this.UsrNamE.value,
      Pass: this.Pass.value,
      ProLng: this.ProLng.value,
      Sts: this.Sts.value,
      FilStr: fil_str,
      InvStr: inv_str,
      SndStr: snd_str,
      AccRep:acc_rep,
      SetStr:set_str
    }


    // if updat
    if(this.data){
      this.ashalService.updateUser(this.data, user)
      .subscribe(
        res => {
          //console.log(res);
          this.dialogRef.close();
          this.tostr.success('تم تحديث المستخدم بنجاح \n User update');
        },
        err => console.error(err)
      )
    }

    // if new
/*     else{
      this.ashalService.newUser(user)
      .subscribe(
        res => {
          //console.log(res);
          this.dialogRef.close();
          this.tostr.success('New User Created');
        },
        err => console.error(err)
      )
    } */


  }


  // close popup form
  onClose(){
    // this.newBreadwinnerForm.reset();
    this.dialogRef.close();
  }

}
import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { AshalService } from '../../../shared/ashal.service';
import { Item } from 'src/app/shared/item';
import {TranslateService} from '@ngx-translate/core';
import {PrintService} from "../../../print.service";

@Component({
  selector: 'app-items-list',
  templateUrl: './items-list.component.html',
  styleUrls: ['./items-list.component.css']
})
export class ItemsListComponent implements OnInit {

  dataSource = new MatTableDataSource();
  print_premisions: boolean = false;
  add_premisions: boolean = false;
  edit_premisions: boolean = false;
  delete_premisions: boolean = false;

  items: Item[];
  listData: any;
  serial: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['index', 'Itm_No', 'Arb_Des','Eng_Des', 'OpenQty' , 'controls'];

  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public printService: PrintService,
    private dialog: MatDialog,
  ) {
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en'); }

  ngOnInit() {
    this.print_premisions =  this.ashalService.is_premision("FilStr_premisions","item_print",true); 
    this.add_premisions =  this.ashalService.is_premision("FilStr_premisions","item_add",true);
    this.edit_premisions =  this.ashalService.is_premision("FilStr_premisions","item_edit",true);
    this.delete_premisions =  this.ashalService.is_premision("FilStr_premisions","item_delete",true);
    this.getItems();
  }

  getItems(){
    return this.ashalService.getItems().subscribe(
      res => {
        if(res){
          this.dataSource.data = res
          this.listData = this.dataSource
          this.items = res
        }
      }
    );
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  applyFilter(filterValue: string){
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

  onDelete(Itm_No: string){
    if (confirm('هل تريد بتأكيد حذف السجيل ؟  \n Do you want to confirm deletion of the record?')) {
      this.ashalService.deleteItem(Itm_No)
      .subscribe(
        res => {
          if(res)
          {
            this.tostr.success('تم حذف السجل بنجاح  \n The record was deleted successfully');
            this.getItems();
          }
          else
          {
            alert('عفواً ... لايمكن حذف السجل لأنه مستخدم  \n this record cant be deleted, aleady used');
          }
        },
        err => console.error(err)
      )
    } else {
      // Do nothing!
    }
  }

  onPrintItems() {
    this.printService
      .printDocument('Item');
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from '../app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
// material module
import { MaterialModule } from '../shared/material';
import { NumbersOnlyDirective } from '../shared/numbers-only.directive';

import { SideNavComponent } from './side-nav/side-nav.component';
import { UnitsListComponent } from './units/units-list/units-list.component';
import { NewUnitComponent } from './units/new-unit/new-unit.component';
import { ItemsListComponent } from './items/items-list/items-list.component';
import { NewItemComponent } from './items/new-item/new-item.component';
import { CategoryListComponent } from './catogory/category-list/category-list.component';
import { NewCategoryComponent } from './catogory/new-category/new-category.component';
import { AccdefListComponent } from './accdef/accdef-list/accdef-list.component';
import { NewAccdefComponent } from './accdef/new-accdef/new-accdef.component';
import { VoucherComponent } from './vouchers/voucher/voucher.component';
import { InvoiceComponent } from './invoices/invoice/invoice.component';
import { ItemComponent } from './items/item/item.component';
import { VouchersListComponent } from './vouchers/vouchers-list/vouchers-list.component';
import { ReportComponent } from './reports/report/report.component';
import { InvoicesListComponent } from './invoices/invoices-list/invoices-list.component';
import { PrintLayoutComponent } from './print/print-layout/print-layout.component';
import { StatementComponent } from './statement/statement.component';
import { InvSearchComponent } from './invoices/inv-search/inv-search.component';
import { InvReportComponent } from './invoices/inv-report/inv-report.component';

import { CategoryReportComponent } from './catogory/category-report/category-report.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { VoucherReportComponent } from './vouchers/voucher-report/voucher-report.component';
import { UserListComponent } from './users/user-list/user-list.component';
import { VoucherPrintComponent } from './vouchers/voucher-print/voucher-print.component';
import { NewUserComponent } from './users/new-user/new-user.component';
import { BranchSelectComponent } from './branch/branch-select/branch-select.component';


@NgModule({
  declarations: [SideNavComponent,
    NumbersOnlyDirective,
    UnitsListComponent,
    NewUnitComponent,
    ItemsListComponent,
    NewItemComponent,
    CategoryListComponent,
    NewCategoryComponent,
    AccdefListComponent,
    NewAccdefComponent,
    VoucherComponent,
    InvoiceComponent,
    ItemComponent,
    VouchersListComponent,
    ReportComponent,
    InvoicesListComponent,
    PrintLayoutComponent,
    StatementComponent,
    InvSearchComponent,
    InvReportComponent,
    CategoryReportComponent,
    VoucherReportComponent,
    UserListComponent,
    VoucherPrintComponent,
    NewUserComponent,
    BranchSelectComponent,

  ],
  imports: [
    FormsModule, 
    NgbModule,
    CommonModule,
    AppRoutingModule,
    MaterialModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [DatePipe],
  entryComponents:[NewUserComponent,NewUnitComponent, NewCategoryComponent, NewAccdefComponent, VouchersListComponent,BranchSelectComponent]
})
export class DashboardModule { }
export class NgbdDatepickerIslamicCivilModule {}

import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { Voucher } from '../../../shared/voucher';
import { ActivatedRoute } from '@angular/router';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { infoData } from './../../../shared/infoData';
import { Router } from '@angular/router';


@Component({
  selector: 'app-voucher-print',
  templateUrl: './voucher-print.component.html',
  styleUrls: ['./voucher-print.component.css']
})
export class VoucherPrintComponent implements OnInit {
  form: FormGroup;
  save: string;
  voucher: Voucher = {};
  id: any;
  opeType: string;
  infoData: infoData;
  getheader: boolean = false;
  // voucher defaults
  accdefs: object;
  currencys: object; // العملات
  csts: object; // مركز التكلفة
  mndobs: object; // المندوبين
  is_arabic:boolean = false;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {

    if(sessionStorage.getItem("langs") == "0")
    {this.is_arabic = true;}
    else
    {this.is_arabic = false;}
    
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // do something
      if(params.lang == 'ar'){
       this.is_arabic = true;
      }
      else{
       this.is_arabic = false;
      }
     //  alert(this.is_arabic);
      
 });
    this.route.params.subscribe((params: { id: any; }) => { this.id = params.id; });
    this.preVoucher();
    this.getVoucher();

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes;

    this.form = fb.group({
      opeType: [],
      gdhead_ID : [],
      gdHDate : [`${hoursstr}:${minutesstr}`, Validators.required],
      gdGDate : [`${year}/${monthstr}/${daystr}`, Validators.required],
      gdNo : [, Validators.required],
      gdMnd : [1, Validators.required],
      RefNo : [''],
      CurTyp : [1,Validators.required],
      details: fb.array([]), // details
      accdefActive : [''],
      balance : [''],
      madeenTotal : [0],
      daenTotal : [0],
      gdMem : [''],
      gdTot : [0],
      totalDiff : [0],
      ArbTaf : [''],
      EngTaf : [''],
    });

    this.ashalService.getinfoData().subscribe(
      res => {
        if (res) {
          this.infoData = res;
          this.getheader = true;
          // this.loading = false;
        }
      }
    );
    
  }

  get detailsForm(){ return this.form.get('details') as FormArray }

  addDetailsShortCut(i:number){
    if(this.detailsForm.length-1 == i){
      this.addDetails();
    }    
  }

  // add details to details form aray
  addDetails(){

    const details = this.fb.group({
      AccNo: ['', Validators.required],
      AccName: ['', Validators.required],
      gdDes: ['', Validators.required],
      madeen: [0, Validators.required],
      daen: [0, Validators.required],
      gdCstNo: [1],
      InvNo: ['0'],
    });

    this.detailsForm.push(details);

  }

  // remove details row
  removeDetails(i: number){
    this.form.controls['accdefActive'].patchValue("");
    this.form.controls['balance'].patchValue("");
    if(this.detailsForm.length ==1){
        this.detailsForm.removeAt(i);
        this.addDetails();
        return false;
    }
    this.detailsForm.removeAt(i);
    
  }

  preVoucher(){
    this.ashalService.preVoucher().subscribe(

      preVoucher => {

        this.currencys = preVoucher.currencys;
        this.mndobs = preVoucher.mndobs;
        this.accdefs = preVoucher.accdefs;
        this.csts = preVoucher.csts;
      }

    );
  }

  getVoucher(){
    this.ashalService.printVoucher(this.id).subscribe(
      voucher => {
        this.voucher = voucher;
      }
    );
  }


  ngOnInit(){
 
  }

print(): void {
  let printContents, popupWin;
  printContents = document.getElementById('print-section').innerHTML;
  popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  popupWin.document.open();
  popupWin.document.write(`
    <html>
      <head>
      <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css" integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS" crossorigin="anonymous">
      </head>
  <body onload="window.print();window.close()">${printContents}</body>
    </html>`
  );
  popupWin.document.close();
}

}


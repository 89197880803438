import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { Voucher } from '../../../shared/voucher';
import { ActivatedRoute } from '@angular/router';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
import { Router } from '@angular/router';
declare const tafqeet: any;

@Component({
  selector: 'app-voucher',
  templateUrl: './voucher.component.html',
  styleUrls: ['./voucher.component.css']
})
export class VoucherComponent implements OnInit {

  form: FormGroup;
  save: string;
  voucher: Voucher = {};
  id: any;
  opeType: string;
  print_premisions: boolean = false;
  // voucher defaults
  accdefs: object;
  currencys: object; // العملات
  csts: object; // مركز التكلفة
  mndobs: object; // المندوبين
  is_arabic:boolean = false;
  loading: boolean = false;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {

    if(sessionStorage.getItem("langs") == "0")
    {this.is_arabic = true;}
    else
    {this.is_arabic = false;}
    
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // do something
      if(params.lang == 'ar'){
       this.is_arabic = true;
      }
      else{
       this.is_arabic = false;
      }
     //  alert(this.is_arabic);
      
 });
    this.route.params.subscribe((params: { id: any; }) => { this.id = params.id; });
    this.preVoucher();
    this.getVoucher();

    let today = new Date();
    let year = today.getFullYear();
    let month = today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes;

    this.form = fb.group({
      opeType: [],
      gdhead_ID : [],
      gdHDate : [`${hoursstr}:${minutesstr}`, Validators.required],
      gdGDate : [`${year}/${monthstr}/${daystr}`, Validators.required],
      gdNo : [, Validators.required],
      gdMnd : [1, Validators.required],
      RefNo : [''],
      CurTyp : [1,Validators.required],
      details: fb.array([]), // details
      accdefActive : [''],
      balance : [''],
      madeenTotal : [0],
      daenTotal : [0],
      gdMem : [''],
      gdTot : [0],
      totalDiff : [0],
      ArbTaf : [''],
      EngTaf : [''],
    });
  }

  get detailsForm(){ return this.form.get('details') as FormArray }

  addDetailsShortCut(i:number){
    if(this.detailsForm.length-1 == i){
      this.addDetails();
    }    
  }

  // add details to details form aray
  addDetails(){

    const details = this.fb.group({
      AccNo: ['', Validators.required],
      AccName: ['', Validators.required],
      gdDes: ['', Validators.required],
      madeen: [0, Validators.required],
      daen: [0, Validators.required],
      gdCstNo: [1],
      InvNo: ['0'],
    });

    this.detailsForm.push(details);

  }

  // remove details row
  removeDetails(i: number){
    this.form.controls['accdefActive'].patchValue("");
    this.form.controls['balance'].patchValue("");
    if(this.detailsForm.length ==1){
        this.detailsForm.removeAt(i);
        this.addDetails();
        return false;
    }
    this.detailsForm.removeAt(i);
    this.getTotal();
  }

  preVoucher(){
    this.ashalService.preVoucher().subscribe(

      preVoucher => {

        this.currencys = preVoucher.currencys;
        this.mndobs = preVoucher.mndobs;
        this.accdefs = preVoucher.accdefs;
        this.csts = preVoucher.csts;
      }

    );
  }

  getVoucher(){
    this.ashalService.getVoucher(this.id).subscribe(
      voucher => {
        this.voucher = voucher;

        if(this.id==0){

          this.form.patchValue({
            opeType: "save",
            gdhead_ID: this.voucher.GdHead.gdhead_ID + 1,
            gdNo: parseInt(this.voucher.GdHead.gdNo) + 1
          });

          this.addDetails();

        }else{
          this.form.patchValue({
            opeType: "update",
            gdhead_ID: this.voucher.GdHead.gdhead_ID,
            gdHDate: this.voucher.GdHead.gdHDate,
            gdGDate: this.voucher.GdHead.gdGDate,
            gdNo: this.voucher.GdHead.gdNo,
            gdMnd: this.voucher.GdHead.gdMnd,
            CurTyp: this.voucher.GdHead.CurTyp,
            RefNo: this.voucher.GdHead.RefNo,
            gdMem: this.voucher.GdHead.gdMem,
          });

          for(var i = 0; i < this.voucher.GdDetails.length; i++){
            this.addDetails();
            this.detailsForm.controls[i]['controls']['AccNo'].patchValue(this.voucher.GdDetails[i].AccNo);
            this.detailsForm.controls[i]['controls']['AccName'].patchValue(this.voucher.GdDetails[i].AccNo);
            this.detailsForm.controls[i]['controls']['gdDes'].patchValue(this.voucher.GdDetails[i].gdDes);
            this.detailsForm.controls[i]['controls']['madeen'].patchValue((this.voucher.GdDetails[i].gdValue > 0 ? this.voucher.GdDetails[i].gdValue : 0) / this.voucher.GdHead.Rate);
            this.detailsForm.controls[i]['controls']['daen'].patchValue((this.voucher.GdDetails[i].gdValue < 0 ? Math.abs(this.voucher.GdDetails[i].gdValue) : 0)/this.voucher.GdHead.Rate);
            this.detailsForm.controls[i]['controls']['gdCstNo'].patchValue(this.voucher.GdDetails[i].gdCstNo);
            this.detailsForm.controls[i]['controls']['InvNo'].patchValue(this.voucher.GdDetails[i].InvNo);
          }
          this.getTotal();
        }

      }
    );
  }

  accdefChanged(value: string, i){
    this.detailsForm.controls[i].patchValue({AccName: value});
    this.detailsForm.controls[i].patchValue({AccNo: value});
    this.form.controls['accdefActive'].patchValue(this.detailsForm.controls[i]['controls']['AccNo'].value);
    this.getActiveAccdefBalance(this.detailsForm.controls[i]['controls']['AccNo'].value);
  }

  getActiveAccdefBalance(AccNo:string){
    this.ashalService.getAccdefBalance(AccNo)
    .subscribe(
      res => {
        this.form.controls['balance'].patchValue(res);
    },
      err => console.error(err)
    )
  }

  onChangeMadeenValue(value : number ,i: number) {
    if(value > 0)this.detailsForm.controls[i].patchValue({daen: 0})
    this.getTotal();
  }

  onChangeDaenValue(value : number ,i: number) {
    if(value > 0)this.detailsForm.controls[i].patchValue({madeen: 0})
    this.getTotal();
  }

  getTotal(): number{
    var madeenTotal: number = 0;
    var daenTotal: number = 0;
    for (let i = 0; i < this.detailsForm.length; i++) { 
     madeenTotal += Number(this.detailsForm.controls[i]['controls']['madeen'].value);
     daenTotal += Number(this.detailsForm.controls[i]['controls']['daen'].value);
    }
    this.form.controls['madeenTotal'].setValue(madeenTotal);
    this.form.controls['daenTotal'].setValue(daenTotal);
    this.form.controls['totalDiff'].setValue(this.form.controls['madeenTotal'].value - this.form.controls['daenTotal'].value);
    this.form.controls['gdTot'].setValue(this.form.controls['madeenTotal'].value);
    return this.form.controls['totalDiff'].value;
 }


  submit(){
    //alert(tafqeet(this.form.controls['daenTotal'].value));
    if(this.form.controls['daenTotal'].value == 0 || this.form.controls['totalDiff'].value != 0)
    {
      alert('عفواً ... لا يمكن حفظ القيد لأنه غير متوازن \n Sorry ... The Voucher can not be saved because it is unbalanced');
      return ;
    }
    this.loading = true;
    this.form.controls['ArbTaf'].setValue(tafqeet(this.form.controls['daenTotal'].value));
    this.form.controls['EngTaf'].setValue(tafqeet(this.form.controls['daenTotal'].value));
    this.form.controls["gdhead_ID"].enable();
    this.voucher = this.form.value;
    this.form.controls["gdhead_ID"].disable();
    this.ashalService.newVoucher(this.voucher)
    .subscribe(
      res => {

        this.form.controls['opeType'].setValue('update');
        this.id = res.GdHead.gdhead_ID;
        this.save = this.id == 0 ? 'save' : 'update';
        if(this.id !=0)this.form.controls['gdNo'].disable();
        alert('تم تسجيل السند بنجاح \n The voucher was successfully registered');
        this.loading = false;
        if (this.print_premisions == true &&  confirm('هل تريد طباعة السند ؟ \n Do you want to print the voucher ?')) 
        {
        this.router.navigate(['dashboard/voucher-print/' + this.id]);
          // Save it!
        } else {
          // Do nothing!
        }

/*         this.tostr.success('تم تسجيل سند القيد بنجاح');
        while(this.detailsForm.length !==0){
          this.detailsForm.removeAt(0);
        }
        this.form.reset();
        this.preVoucher();
        this.getVoucher(); */
      },
      err => console.error(err)
    )

  }

  NewVoucher(){
    this.router.navigate(['dashboard/vouchers-list']);
  }

  ngOnInit(){
    this.print_premisions =  this.ashalService.is_premision("SndStr_premisions","snd_print",true); 
    this.save = this.id == 0 ? 'save' : 'update';
    this.form.controls['gdhead_ID'].disable();
    this.form.controls['accdefActive'].disable();
    this.form.controls['balance'].disable();
    this.form.controls['madeenTotal'].disable();
    this.form.controls['daenTotal'].disable();
    this.form.controls['totalDiff'].disable();

    if(this.id !=0)this.form.controls['gdNo'].disable();


  }

}

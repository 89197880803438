import { Component, OnInit } from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import { AshalService } from '../../shared/ashal.service';
import { user_info } from '../../shared/user_info';
import {Router} from "@angular/router"
import {MatDialog, MatDialogConfig} from '@angular/material';
import {BranchSelectComponent} from '../branch/branch-select/branch-select.component';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.css']
})
export class SideNavComponent implements OnInit {
  private isRtl: boolean;
  // private _dirChangeSubscription = Subscription.EMPTY; 
  unit_permision: boolean = false;
  cat_permision: boolean = false;
  item_permision: boolean = false;
  accdef_permision: boolean = false;
  inv_permision: boolean = false;
  snd_permision: boolean = false;
  stkRep_permision: boolean = false;
  accRep_permision: boolean = false;
  stksum_permision: boolean = false;
  setStr_premisions: boolean = false;
  constructor(private translate: TranslateService,  
      private ashalService: AshalService,
      private dialog: MatDialog,
      private router: Router
    ) {
                    
      

   }

  ngOnInit() {
    if(sessionStorage.getItem("langs") == '0')
    {this.useLanguage('ar');}
    else
    {this.useLanguage('en');}
    
    var UserInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    if(document.dir ="ltr")
    {document.getElementById('UserName').innerHTML = "Hi " + UserInfo["UsrNamE"];}
    else
    {document.getElementById('UserName').innerHTML =  "مرحباً " + UserInfo["UsrNamE"];}
    
    this.unit_permision = this.ashalService.is_premision("FilStr_premisions","unit",true);
    this.cat_permision = this.ashalService.is_premision("FilStr_premisions","cat",true);
    this.item_permision = this.ashalService.is_premision("FilStr_premisions","item",true);
    this.accdef_permision = this.ashalService.is_premision("FilStr_premisions","accdef",true);
    this.inv_permision = this.ashalService.is_premision("InvStr_premisions","sales",true);
    this.snd_permision = this.ashalService.is_premision("SndStr_premisions","snd",true);
    this.stkRep_permision = this.ashalService.is_premision("AccRep_premisions","StkRep",true);
    this.accRep_permision = this.ashalService.is_premision("AccRep_premisions","AccRep",true);
    this.stksum_permision = this.ashalService.is_premision("AccRep_premisions","SumRep",true);
    this.setStr_premisions = this.ashalService.is_premision("SetStr_premisions","user",true);
  }

  // is_permision(key1:string,key2:string){
  //   return this.ashalService.is_premision(key1,key2,true);
  // }

  // useLanguage(language: string) {
  //     this.translate.use(language);
  // }
  useLanguage(language: string) {
    var UserInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    // alert(language);
    if(language == 'ar'){
      
      // document.body.setAttribute("dir", "rtl");
      document.getElementById('UserName').innerHTML = "مرحباً " +UserInfo["UsrNamE"];
      document.getElementById('BranchName').innerHTML = sessionStorage.getItem("brn_name");
      this.translate.use(language);
      document.dir ="rtl";
      sessionStorage.setItem("langs","0");
      
    }
    else{
      // document.body.setAttribute("dir", "ltr");
      document.getElementById('UserName').innerHTML =   "Hi " + UserInfo["UsrNamE"];
      document.getElementById('BranchName').innerHTML = sessionStorage.getItem("brn_namee");
      document.dir ="ltr";
      this.translate.use(language);
      sessionStorage.setItem("langs","1");

    }

}

   Exit()
   {
    sessionStorage.clear();
    this.router.navigate([''])
   }

   Branch_Sel()
   {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '30%';
      const dialogCallBack = this.dialog.open(BranchSelectComponent, dialogConfig);
      dialogCallBack.afterClosed().subscribe(() => {
        if(sessionStorage.getItem("langs") == '0')
            {this.useLanguage('ar');}
            else
            {this.useLanguage('en');}
      });
   }
}

import { Component, OnInit, Inject } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import {Router} from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import {TranslateService} from '@ngx-translate/core';
import { Branch } from 'src/app/shared/branch';

@Component({
  selector: 'app-branch-select',
  templateUrl: './branch-select.component.html',
  styleUrls: ['./branch-select.component.css']
})
export class BranchSelectComponent implements OnInit {

  branchs: Branch[];
  is_arabic:boolean = false;
  constructor(    
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    private router: Router,
    public fb: FormBuilder,
    public dialogRef: MatDialogRef<BranchSelectComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) {
      if(sessionStorage.getItem("langs") == "0")
      {this.is_arabic = true;}
      else
      {this.is_arabic = false;}

      this.ashalService.check_is_login();
      translate.setDefaultLang('en');
  }

  newForm = this.fb.group({

    BrnSel : ['']

  });

  get BrnSel() { return this.newForm.get('BrnSel') }

  ngOnInit() {
    this.ashalService.checkbranch(sessionStorage.getItem('brn_no')).subscribe(
      branchs =>{
         this.branchs = branchs
       } 
    );
  }

  onClose(){
    // this.newBreadwinnerForm.reset();
    this.dialogRef.close();
  }

  BranchSel() {

    if(this.BrnSel.value != '')
    {
      this.ashalService.getbranch(this.BrnSel.value).subscribe(
        res =>{
          sessionStorage.setItem('urlcon',res[0].Branch_mem);
          sessionStorage.setItem('brn_name',res[0].Branch_Name);
          sessionStorage.setItem('brn_namee',res[0].Branch_NameE);
          //this.router.navigate(['dashboard/user']);
          this.router.navigate(['dashboard/report']);
          this.dialogRef.close();
          alert('تم تغيير الفرع بنجاح , أرجوا الضغط على زر التحديث في أعلى الشاشة  \n The branch was changed successfully, please press the refresh button at the top of the screen');
         } 
      );
    }
    else
    {
      alert('من فضلك ... أختر الفرع أولاً  \n Please ... choose the branch first');
    }

    }

}

 import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule } from '@angular/forms';

import { AshalService } from '../../../shared/ashal.service';
import { NewUserComponent } from '../new-user/new-user.component';
import { User } from 'src/app/shared/user';

import {PrintService} from "../../../print.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  dataSource = new MatTableDataSource();
  print_premisions: boolean = false;
  edit_premisions: boolean = false;

  users: User[];
  user: User = {
    UsrNamA: "",
    UsrNamE: ""
  };
  listData: any;
  serial: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['index', 'UsrNo', 'UsrNamA', 'UsrNamE', 'controls'];

  form: FormGroup;

  constructor(
    private tostr: ToastrService,
    public fb: FormBuilder,
    private ashalService: AshalService,
    private dialog: MatDialog,
    public printService: PrintService,
    private translate: TranslateService
  ) {
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
    this.form = fb.group({
      UsrNamA : ['', Validators.required],
      UsrNamE : ['', Validators.required]
    });
  }

  get UsrNamA() { return this.form.get('UsrNamA') }
  get UsrNamE() { return this.form.get('UsrNamE') }

  ngOnInit() {
    this.print_premisions =  this.ashalService.is_premision("SetStr_premisions","user_print",true); 
    this.edit_premisions =  this.ashalService.is_premision("SetStr_premisions","user_edit",true);
    this.getUsers();
  }

  submit(){
/*     if(this.form.valid){
      this.user = this.form.value;
      this.ashalService.newUnit(this.user).subscribe(
        res => {
            this.tostr.success('New Unit Created');
            this.getUnits();
            this.form.reset();
        },
        err => console.error(err)
      );
    } */
  }

  getUsers(){
    return this.ashalService.getUsers(sessionStorage.getItem('brn_no')).subscribe(
      res => {
        if(res){
          //console.log(this.users)
          this.dataSource.data = res
          this.listData = this.dataSource
          this.users = res
          //console.log(this.users)
        }
      }
    );
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  applyFilter(filterValue: string){
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

/*   onCreateNew(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    const dialogCallBack = this.dialog.open(NewUnitComponent, dialogConfig);
    dialogCallBack.afterClosed().subscribe(() => {
      this.getUnits();
    });
  } */

  onEdit(User_No: string){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '55%';
    dialogConfig.data = User_No;
    const dialogCallBack = this.dialog.open(NewUserComponent, dialogConfig);
    dialogCallBack.afterClosed().subscribe(() => {
      this.getUsers();
    });
  }

  onDelete(Unit_No: string){
/*     if (confirm('هل تريد بتأكيد حذف السجيل ؟  \n Do you want to confirm deletion of the record?')) {
      this.ashalService.deleteUnit(Unit_No)
      .subscribe(
      res => {
        if(res)
        {
          this.tostr.success('تم حذف السجل بنجاح  \n The record was deleted successfully');
          this.getUnits();
        }
        else
        {
          alert('عفواً ... لايمكن حذف السجل لأنه مستخدم  \n this record cant be deleted, aleady used');
        }
      },
      err => console.error(err)
    )
    } else {
      // Do nothing!
    } */
    
}

onPrintUnits() {
  this.printService
    .printDocument('user');
}

}

import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { Invoice } from '../../../shared/invoice';
import { ActivatedRoute,Router } from '@angular/router';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';
declare const tafqeet: any;

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {

  form: FormGroup;
  save: string;
  invoice: Invoice = {};
  id: any;
  print_premisions: boolean = false;
  // invoice defaults
  accdefs: object;
  accdefss: object;
  cashAccdefs: object;
  currencys: object; // العملات
  csts: object; // مركز التكلفة
  mndobs: object; // المندوبين
  items: object; // الأصناف
  stores: object; // المستودعات

  InvHed_ID: number;
  userNo : number;
  userName : string;
  // get units based on selected item
  units: any[] = [];
  itemsRows: any[] = [];
  is_arabic:boolean = false;
  loading: boolean = true;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute
  ) {

    if(sessionStorage.getItem("langs") == "0")
    {this.is_arabic = true;}
    else
    {this.is_arabic = false;}
    var UserInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    this.userNo = Number(UserInfo["Usr_ID"]);
    this.userName = UserInfo["UsrNamA"]; 
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // do something
      if(params.lang == 'ar'){
       this.is_arabic = true;
      }
      else{
       this.is_arabic = false;
      }
     //  alert(this.is_arabic);
      
 });
    this.route.params.subscribe((params: { id: any; }) => { this.id = params.id; });
    this.preInvoice();
    this.getInvoice();
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes;
    //alert(`${monthstr}-${daystr}-${year}, ${monthstr}/${daystr}/${year}.`);
    this.form = fb.group({
      opeType: [],
      InvHed_ID : [],
      InvNo : [],
      HDat : [`${hoursstr}:${minutesstr}`,Validators.required],
      GDat : [`${year}/${monthstr}/${daystr}` ,Validators.required],
      InvCashPay : [0],
      CusVenNo : [""],
      CusVenNm : [""],
      accdefActive : [''],
      CashAcc : [1020001],
      CusVenTel : [],
      CusVenAdd : [],
      CurTyp : [1],
      RefNo : [],
      MndNo : [0, Validators.required],
      Remark : [],
      Vat_No : [],
      CustPri : [0],
      InvCstNo : [1],
      InvCstNo2 : [1],
      CashPay : [0],
      InvTot : [0],
      InvQty : [0],
      InvDisVal : [0,Validators.required],
      Commission : [0,Validators.required],
      InvDisPrs : [0],
      ShippingRates : [0,Validators.required],
      InvNet : [0],
      InvCost : [0],
      ShipExpens : [0,Validators.required],
      InvWight_T : [0],
      TaxTot : [0],
      ItmDes2 : [],
      SalsManNo : [this.userNo],
      SalsManNam : [this.userName],
      ArbTaf: [''], //  التفقيط العربي
      EngTaf : [''], //  التفقيط الانجلي
      details: fb.array([]), // details
    });
  }

  get detailsForm(){ return this.form.get('details') as FormArray }

  addDetailsShortCut(i:number){
    if(this.detailsForm.length-1 == i){
      this.addDetails();
    }    
  }

  // add details to details form aray
  addDetails(){
    const details = this.fb.group({
      ItmNo : ["0"],
      ItmDes : [],
      ItmDesE : [],
      ItmUnt : [],
      UnitIndex : [], // get index of unit (unit1 or unit2  ..3 ..4 ..5)
      StoreNo : [1],
      Qty : [1],
      Price : [0],
      Tax : [0],
      ItmDis : [0],
      Pack : [0],
      Cost : [0],
      Amount : [0],
    });
    
    this.units.push([]);
    this.detailsForm.push(details);
    
    this.itemsRows.push([]);
    this.getInvoiceTotal();
    this.getNetTotal();

  }

  // remove details row
  removeDetails(i: number){
    if(this.detailsForm.length ==1){
        this.detailsForm.removeAt(i);
        this.addDetails();
        return false;
    }
    this.detailsForm.removeAt(i);
    this.units.splice(i, 1);
    this.itemsRows.splice(i, 1);
    this.getInvoiceTotal();
  }

  preInvoice(){
    this.ashalService.preInvoice().subscribe(

      preInvoice => {
        this.currencys = preInvoice.currencys;
        this.mndobs = preInvoice.mndobs;
        this.accdefs = preInvoice.accdefs;
        this.accdefss = preInvoice.accdefss;
        this.cashAccdefs = preInvoice.cashAccdefs;
        this.csts = preInvoice.csts;
        this.stores = preInvoice.stores;
        this.items = preInvoice.items;
        this.loading = false;
      }
    );
  }

  getInvoice(){
    this.ashalService.getInvoice(this.id).subscribe(
      invoice => {
        this.invoice = invoice;
        //console.log(invoice);
        

        if(this.id==0){
          this.form.patchValue({
            opeType: "save",
            InvHed_ID: this.invoice.INVHED.InvHed_ID + 1,
            InvNo: parseInt(this.invoice.INVHED.InvNo) + 1,
          });

          this.addDetails();

        }else{
          this.form.patchValue({
            opeType: "update",
            InvHed_ID: this.invoice.INVHED.InvHed_ID,
            InvNo: this.invoice.INVHED.InvNo,
            HDat : this.invoice.INVHED.LTim,
            GDat : this.invoice.INVHED.GDat,
            InvCashPay : this.invoice.INVHED.InvCashPay,
            CusVenNo : this.invoice.INVHED.CusVenNo,
            CusVenNm : this.invoice.INVHED.CusVenNm,
            CashAcc : this.invoice.INVHED.CashAcc,
            CusVenTel : this.invoice.INVHED.CusVenTel,
            CusVenAdd : this.invoice.INVHED.CusVenAdd,
            CurTyp : this.invoice.INVHED.CurTyp,
            RefNo : this.invoice.INVHED.RefNo,
            MndNo : this.invoice.INVHED.MndNo,
            Remark : this.invoice.INVHED.Remark,
            Vat_No : this.invoice.INVHED.Vat_No,
            CustPri : this.invoice.INVHED.CustPri,
            InvCstNo : this.invoice.INVHED.InvCstNo,
            InvCstNo2 : this.invoice.INVHED.InvCstNo2,
            CashPay : this.invoice.INVHED.CashPay,
            InvTot : this.invoice.INVHED.InvTot,
            InvQty : this.invoice.INVHED.InvQty,
            InvDisVal : this.invoice.INVHED.InvDisVal,
            Commission : this.invoice.INVHED.Commission,
            InvDisPrs : this.invoice.INVHED.InvDisPrs,
            ShippingRates : this.invoice.INVHED.ShippingRates,
            InvNet : this.invoice.INVHED.InvNet,
            InvCost : this.invoice.INVHED.InvCost,
            ShipExpens : this.invoice.INVHED.ShipExpens,
            InvWight_T : this.invoice.INVHED.InvWight_T,
            TaxTot : this.invoice.INVHED.TaxTot,
            SalsManNo : this.invoice.INVHED.SalsManNo,
            SalsManNam : this.invoice.INVHED.SalsManNam,
          });
    
          for(var i = 0; i < this.invoice.INVDET.length; i++){
            this.addDetails();
            this.detailsForm.controls[i]['controls']['ItmNo'].patchValue(this.invoice.INVDET[i].ItmNo);
            //alert(this.invoice.INVDET[i].ItmUntE);
            this.FillUnits(this.invoice.INVDET[i].ItmNo , i,this.invoice.INVDET[i].ItmUntE);
            this.detailsForm.controls[i]['controls']['ItmDes'].patchValue(this.invoice.INVDET[i].ItmNo);
            //this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(this.invoice.INVDET[i].ItmUntE) ;
            this.detailsForm.controls[i]['controls']['StoreNo'].patchValue(this.invoice.INVDET[i].StoreNo);
            this.detailsForm.controls[i]['controls']['Qty'].patchValue(this.invoice.INVDET[i].Qty*-1);
            this.detailsForm.controls[i]['controls']['Price'].patchValue(Number(this.invoice.INVDET[i].Price).toFixed(2));
            this.detailsForm.controls[i]['controls']['Tax'].patchValue(this.invoice.INVDET[i].Tax);    
            this.detailsForm.controls[i]['controls']['Cost'].patchValue(this.invoice.INVDET[i].Cost);         
            this.detailsForm.controls[i]['controls']['ItmDis'].patchValue(this.invoice.INVDET[i].ItmDis);
            this.detailsForm.controls[i]['controls']['Pack'].patchValue(this.invoice.INVDET[i].ItmUntPak);            
            this.detailsForm.controls[i]['controls']['Amount'].patchValue(Number(this.invoice.INVDET[i].Amount).toFixed(2));
            //this.itemChanged(this.invoice.INVDET[i].ItmNo , i);
            

          }
          this.getInvoiceTotal();
        }

      }
    );
  }

  accdefChanged(){
    //var x = document.getElementById("CustNoNumbers")
    var y : string = this.form.controls['CusVenNo'].value;
    //this.form.controls['CusVenNo'].patchValue(value);
    //alert(this.form.controls['CusVenNo'].value);
    this.ashalService.getAccDataCust(y).subscribe(
      accData => {
        if(this.is_arabic == true)
        {
          this.form.controls['CusVenNm'].patchValue(accData.AccData[0].Arb_Des);
        }
        else
        {
          this.form.controls['CusVenNm'].patchValue(accData.AccData[0].Eng_Des);
        }
        this.form.controls['CusVenTel'].patchValue(accData.AccData[0].Telphone1);
        this.form.controls['CusVenAdd'].patchValue(accData.AccData[0].Adders);
        this.form.controls['MndNo'].patchValue(accData.AccData[0].Mnd);
        this.form.controls['Vat_No'].patchValue(accData.AccData[0].Vat_No);
        this.form.controls['CustPri'].patchValue(accData.AccData[0].Price);
      }
    )
    //this.form.controls['CusVenNm'].patchValue(this.form.controls['CusVenNo'].value);
    
  }

// get item desc when item changed  
  itemChanged(ItmNo: string, i:number){
    this.ashalService.getItemData(ItmNo).subscribe(
      itemData => {
        this.itemsRows[i] = itemData;
        this.units[i] = itemData.units;
        this.detailsForm.controls[i].patchValue({ItmDes: ItmNo});
        this.detailsForm.controls[i].patchValue({ItmNo: ItmNo});
        this.form.controls['ItmDes2'].patchValue(ItmNo);
        this.unitChanged(itemData.DefultUnit - 1, i);
        this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(this.units[i][parseInt(itemData.DefultUnit)-1].ItmUntE);
        //this.detailsForm.controls[i]['controls']['Tax'].patchValue(itemData.tax);
        //console.log(this.units[i]);
        this.FillUnits(ItmNo , i,this.units[i][parseInt(itemData.DefultUnit)-1].Eng_Des);
        this.calculateAmount(i);
        this.getInvoiceTotal();
      }
    );
  }

  FillUnits(ItmNo: string, i:number,UnitDef:string){
    //alert(this.form.controls['CustPri'].value);
    this.ashalService.getItemData(ItmNo).subscribe(
      itemData => {
        this.itemsRows[i] = itemData;
        this.units[i] = itemData.units;
        if(UnitDef == "")
        {
          this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(this.units[i]);
        }
        else
        {
          this.detailsForm.controls[i]['controls']['ItmUnt'].patchValue(UnitDef);
        }
      }
    );
  }


  // get price of unit
  unitChanged(ItmUnt: number, i:number){
    //console.log(ItmUnt);
    let o = this.itemsRows[i].unitsPrices[0];
    let m = this.itemsRows[i].unitsPack[0];
    var key = Object.keys(o)[ItmUnt];
    var keyp = Object.keys(m)[ItmUnt];
    let value = o[key];
    let valuepack = m[keyp];
    if(this.form.controls['CustPri'].value == 0 )
    {
       this.detailsForm.controls[i]['controls']['Price'].patchValue(value);
    }
    else if(this.form.controls['CustPri'].value == 1)
    {
      this.detailsForm.controls[i]['controls']['Price'].patchValue(this.itemsRows[i].Price1 * valuepack);
    }
    else if(this.form.controls['CustPri'].value == 2)
    {
      this.detailsForm.controls[i]['controls']['Price'].patchValue(this.itemsRows[i].Price2 * valuepack);
    }
    else if(this.form.controls['CustPri'].value == 3)
    {
      this.detailsForm.controls[i]['controls']['Price'].patchValue(this.itemsRows[i].Price3 * valuepack);
    }
    else if(this.form.controls['CustPri'].value == 4)
    {
      this.detailsForm.controls[i]['controls']['Price'].patchValue(this.itemsRows[i].Price4 * valuepack);
    }
    else if(this.form.controls['CustPri'].value == 5)
    {
      this.detailsForm.controls[i]['controls']['Price'].patchValue(this.itemsRows[i].Price5 * valuepack);
    }
    if(this.itemsRows[i].ItmNature == 2)
    {
      var price : number = this.detailsForm.controls[i]['controls']['Price'].value;
      var taxval : number = Number(this.itemsRows[i].tax) + 100;
      price = (price*100) / taxval;
      this.detailsForm.controls[i]['controls']['Price'].patchValue(price.toFixed(2));
    }
    this.detailsForm.controls[i]['controls']['Pack'].patchValue(valuepack);
    this.detailsForm.controls[i]['controls']['Cost'].patchValue(this.itemsRows[i].AvrageCost * valuepack);
    this.detailsForm.controls[i]['controls']['Tax'].patchValue(this.itemsRows[i].tax);
    this.calculateAmount(i);
    this.getInvoiceTotal();
    this.detailsForm.controls[i]['controls']['UnitIndex'].patchValue(ItmUnt);
    console.log(key,value);
  }

  calculateAmount(i){
    var amountBeforeDiscount = this.detailsForm.controls[i]['controls']['Price'].value * this.detailsForm.controls[i]['controls']['Qty'].value;
    var discount = this.detailsForm.controls[i]['controls']['ItmDis'].value == 0 ? 0 : ((this.detailsForm.controls[i]['controls']['ItmDis'].value ) / 100) * (amountBeforeDiscount);
    var amountAfterDiscount = amountBeforeDiscount - discount;
    // var tax = (this.detailsForm.controls[i]['controls']['Tax'].value / 100) * amountAfterDiscount;
    // var amountAfterDiscount_Tax = amountAfterDiscount + tax;    
    this.detailsForm.controls[i]['controls']['Amount'].patchValue(amountAfterDiscount.toFixed(2));
    
    // udpate invoice amount total and qty total
    this.getInvoiceTotal();

  }

  getInvoiceTotal(){
    var tax : number = 0 ;
    var invTotal: number = 0;
    var taxTotal: number = 0;
    var InvQty: number = 0;
    for (let i = 0; i < this.detailsForm.length; i++) { 
      invTotal += Number(this.detailsForm.controls[i]['controls']['Amount'].value);
      tax = (this.detailsForm.controls[i]['controls']['Tax'].value / 100) * Number(this.detailsForm.controls[i]['controls']['Amount'].value);
      taxTotal += tax;      
      InvQty += Number(this.detailsForm.controls[i]['controls']['Qty'].value);
    }
    this.form.controls['InvTot'].patchValue(invTotal.toFixed(2));
    this.form.controls['TaxTot'].patchValue(taxTotal.toFixed(2));
    this.form.controls['InvQty'].patchValue(InvQty.toFixed(2));
    this.getNetTotal();


  }

  getDisVal(){
    this.form.controls['InvDisVal'].patchValue((this.form.controls['InvDisPrs'].value/100) * this.form.controls['InvTot'].value) ;
    this.getInvoiceTotal();
  }

  getNetTotal(){
    var Commission = parseFloat(this.form.controls['Commission'].value);
    var ShippingRates = parseFloat(this.form.controls['ShippingRates'].value);
    var ShipExpens = parseFloat(this.form.controls['ShipExpens'].value);
    var invTotal = parseFloat(this.form.controls['InvTot'].value);
    var InvDisVal = parseFloat(this.form.controls['InvDisVal'].value);
    var invTotalCost : number = 0;
    var InvPayVal = 0;

    for (let i = 0; i < this.detailsForm.length; i++) 
    { 
      invTotalCost += Number(this.detailsForm.controls[i]['controls']['Cost'].value) * Number(this.detailsForm.controls[i]['controls']['Qty'].value);
    }

    if(this.form.controls['InvCashPay'].value != 0)
    {InvPayVal = parseFloat(this.form.controls['CashPay'].value);}
    if(this.form.controls['InvDisVal'].value != 0 && this.form.controls['TaxTot'].value != 0)
    {
       this.form.controls['TaxTot'].patchValue(((invTotal - InvDisVal) * 0.05).toFixed(2));
    }
    var TaxTot = parseFloat(this.form.controls['TaxTot'].value);
//    for (let i = 0; i < this.detailsForm.length; i++) { 
//     invTotal += Number(this.detailsForm.controls[i]['controls']['Amount'].value);
//    }
    this.form.controls['InvCost'].setValue((invTotalCost + ShippingRates + ShipExpens).toFixed(2));
    this.form.controls['InvNet'].setValue(((invTotal) + (Commission + ShippingRates + ShipExpens + TaxTot) - InvDisVal - InvPayVal).toFixed(2));

  }

  taxwithprice()
  {

  }
  submit(){

    if(this.form.controls['InvCashPay'].value == 1 && this.form.controls['CusVenNo'].value == '')
    {
      alert('عفواً ... لا يمكن حفظ الفاتورة بدون عميل  \n Sorry ... The invoice can not be saved because it is uncustomer');
      return ;
    }
    this.loading = true;
    this.form.controls["InvNo"].enable();
    //alert(this.userName);
    this.form.controls['SalsManNam'].setValue(this.userName);
    this.form.controls['SalsManNo'].setValue(this.userNo);
    this.form.controls['ArbTaf'].setValue(tafqeet(this.form.controls['InvNet'].value));
    this.form.controls['EngTaf'].setValue(tafqeet(this.form.controls['InvNet'].value));
    this.invoice = this.form.value;
    // console.log(this.invoice)
    this.ashalService.newInvoice(this.invoice)
    .subscribe(
      res => {
        
        this.id = res.INVHED.InvHed_ID;
        this.form.controls["opeType"].setValue("update") ;
        this.save = this.id == 0 ? 'save' : 'update';
        if(this.id !=0)this.form.controls['InvNo'].disable();
        alert('تم تسجيل الفاتورة بنجاح \n The invoice was successfully registered');
        this.loading = false;
        if (this.print_premisions == true && confirm('هل تريد طباعة الفاتورة ؟ \n Do you want to print the invoice ?')) 
        {
          this.router.navigate(['dashboard/inv-report/' + this.id]);
          // Save it!
        } else {
          // Do nothing!
        }
        //alert(this.id);
/*         while(this.detailsForm.length !==0){
          this.detailsForm.removeAt(0);
        }
        this.form.reset();
        this.preInvoice();
        this.getInvoice(); */
      },
      err => console.error(err)
    )

  }

  NewInvoice(){
    this.router.navigate(['dashboard/invoices-list']);
    /* while(this.detailsForm.length !==0){
        this.detailsForm.removeAt(0);
    }
    this.form.reset();
    this.preInvoice(); */
  }

  ngOnInit() {
    this.print_premisions =  this.ashalService.is_premision("InvStr_premisions","sales_print",true); 
    this.save = this.id == 0 ? 'save' : 'update';
    if(this.id !=0)this.form.controls['InvNo'].disable();
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import { AshalService } from '../../../shared/ashal.service';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  sales:object;
  purchases:object;
  r_sales:object;
  r_purchases:object;
  voucher_j:object;
  voucher_r:object;
  voucher_p:object;

  txtDate: any ="2018-12-01" ;
  sales_count:number = 0;
  purchases_count:number = 0;
  r_sales_count:number = 0;
  r_purchases_count:number = 0;
  voucher_j_count:number = 0;
  voucher_r_count:number = 0;
  voucher_p_count:number = 0;

  sales_sum:number = 0;
  purchases_sum:number = 0;
  r_sales_sum:number = 0;
  r_purchases_sum:number = 0;
  voucher_j_sum:number = 0;
  voucher_r_sum:number = 0;
  voucher_p_sum:number = 0;

  
  constructor(
    private ashalService: AshalService,
    private router: Router ) 
    {

    this.ashalService.check_is_login();
    
    let today = new Date();

    let year = today.getFullYear();
    let month = today.getMonth()+1; //add 1 because the count starts from 0 instead of 1. 
    let day = today.getDate(); // new Date.getDate returns the day of the MONTH, not week.
    let hours = today.getHours();
    let minutes = today.getMinutes();
    let monthstr = month < 10 ? '0'+month : month;
    let daystr = day < 10 ? '0'+day : day;
    let hoursstr = hours < 10 ? '0'+hours : hours;
    let minutesstr = minutes < 10 ? '0'+minutes : minutes;

    this.txtDate =  year+"-"+monthstr+"-"+daystr;
    

    this.preInvRep()
   }

   
   preInvRep(){


    this.ashalService.getInvRep(this.txtDate.replace(/-/g,"/"),this.txtDate.replace(/-/g,"/")).subscribe(
      InvRep => {
        this.sales = InvRep.sales;
        this.sales_count = this.sales[0].InvCount,
        this.sales_sum = this.sales[0].InvSum/1.00,
        this.purchases = InvRep.purchases;
        this.purchases_count = this.purchases[0].InvCount,
        this.purchases_sum = this.purchases[0].InvSum/1.00,
        this.r_sales = InvRep.r_purchases;
        this.r_sales_count = this.r_sales[0].InvCount,
        this.r_sales_sum = this.r_sales[0].InvSum/1.00,
        this.r_purchases = InvRep.r_sales;
        this.r_purchases_count = this.r_purchases[0].InvCount,
        this.r_purchases_sum = this.r_purchases[0].InvSum/1.00,
        this.voucher_j = InvRep.voucher_j;
        this.voucher_j_count = this.voucher_j[0].InvCount,
        this.voucher_j_sum = this.voucher_j[0].InvSum/1.00,
        this.voucher_r = InvRep.voucher_r;
        this.voucher_r_count = this.voucher_r[0].InvCount,
        this.voucher_r_sum = this.voucher_r[0].InvSum/1.00,
        this.voucher_p = InvRep.voucher_p;
        this.voucher_p_count = this.voucher_p[0].InvCount,
        this.voucher_p_sum = this.voucher_p[0].InvSum/1.00
      }
    );
  }

  ngOnInit() {
    

  }

}



import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import { timer } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PrintService {
  isPrinting = false;
  
  constructor(private router: Router) { }

  printDocument(documentName: string) {
    this.isPrinting = true;
    this.router.navigate(['/',
      { outlets: {
        'print': ['print', documentName]
      }}]);
      window.setTimeout(window.print,2000);
      //window.print();
  }

  onDataReady() {
    setTimeout(() => {
      this.isPrinting = false;
      this.router.navigate([{ outlets: { print: null }}]);
    });
  }
}

import { Component, OnInit, ViewChild } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { DataSource } from '@angular/cdk/collections';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';

import { AshalService } from '../../../shared/ashal.service';
import { Voucher } from 'src/app/shared/voucher';
import {TranslateService} from '@ngx-translate/core';


@Component({
  selector: 'app-vouchers-list',
  templateUrl: './vouchers-list.component.html',
  styleUrls: ['./vouchers-list.component.css']
})
export class VouchersListComponent implements OnInit {

  dataSource = new MatTableDataSource();

  print_premisions: boolean = false;
  add_premisions: boolean = false;
  edit_premisions: boolean = false;
  delete_premisions: boolean = false;

  vouchers: Voucher[];
  listData: any;
  serial: number = 1;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['index', 'gdNo', 'gdMem', 'gdGDate', 'gdTot',  'gdhead_ID'];

  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    private dialog: MatDialog,
  ) { 
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
}

  ngOnInit() {
    this.print_premisions =  this.ashalService.is_premision("SndStr_premisions","snd_print",true); 
    this.add_premisions =  this.ashalService.is_premision("SndStr_premisions","snd_add",true);
    this.edit_premisions =  this.ashalService.is_premision("SndStr_premisions","snd_edit",true);
    this.delete_premisions =  this.ashalService.is_premision("SndStr_premisions","snd_delete",true);
    this.getVouchers();
  }

  getVouchers(){
    return this.ashalService.getVouchers().subscribe(
      res => {
        if(res){
          this.dataSource.data = res
          this.listData = this.dataSource
          this.vouchers = res
        }
      }
    );
  }

  ngAfterViewInit(){
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  
  applyFilter(filterValue: string){
    filterValue = filterValue.trim();
    filterValue = filterValue.toLocaleLowerCase();
    this.dataSource.filter = filterValue;
  }

  // onDelete(Itm_No: string){
  //   this.ashalService.deleteItem(Itm_No)
  //   .subscribe(
  //     res => {
  //       console.log(res);
  //       this.tostr.success('item deleted');
  //       this.getVouchers();
  //     },
  //     err => console.error(err)
  //   )
  // }

}

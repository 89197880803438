import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, FormGroup, FormBuilder, Validators, FormsModule, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AshalService } from '../../../shared/ashal.service';
import { Item } from '../../../shared/item';
import { ActivatedRoute,Router } from '@angular/router';
import {TranslateService,LangChangeEvent} from '@ngx-translate/core';


@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {

  form: FormGroup;
  save: string;
  item: Item = {};
  id: any;

  // item defaults
  accdefs: object;
  categorys: object; // مجموعات الأصناف
  units: object; // الوحدات
  is_arabic:boolean = false;
  constructor(
    private translate: TranslateService,
    private tostr: ToastrService,
    private ashalService: AshalService,
    public fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) {
    if(sessionStorage.getItem("langs") == "0")
    {this.is_arabic = true;}
    else
    {this.is_arabic = false;}
    
    this.ashalService.check_is_login();
    this.translate.setDefaultLang('en');
    this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      // do something
      if(params.lang == 'ar'){
       this.is_arabic = true;
      }
      else{
       this.is_arabic = false;
      }
     //  alert(this.is_arabic);
      
 });
    this.route.params.subscribe((params: { id: any; }) => { this.id = params.id; });
    this.preItem();
    this.getItem();
    this.form = fb.group({
      opeType: [],
      Itm_No : ["", Validators.required],
      Group_Code : [],
      ItmCat : ["",Validators.required],
      Arb_Des : ["",Validators.required],
      Eng_Des : ["",Validators.required],
      DefultVendor : [""],
      StartCost : [0],
      AvrageCost : [0],
      LastCost : [0],
      OpenQty : [0],
      Shipping_Cost : [0],
      SeaCost : [0],
      QtyMax : [],
      ItmTyp : [0],
      Tax : [],
      ItmNature : [0],
      QtyLvl : [],
      ItmLoc : [],
      Sn : [false],
      Lot : [false],
      DMY : [0],
      LrnExp : [],
      Note : [],


      DefultUnit : ['1'],
    
      // unit 1
      Unit1 : ['',Validators.required],
      Pack1 : ['1',Validators.required],
      UntPri1 : ['',Validators.required],
      BarCod1 : ['0'],
  
      // unit 2
      Unit2 : [0],
      Pack2 : [0],
      UntPri2 : [0],
      BarCod2 : ['0'],
  
  
      // unit 3
      Unit3 : [0],
      Pack3 : [0],
      UntPri3 : [0],
      BarCod3 : ['0'],
  
      // unit 4
      Unit4 : [0],
      Pack4 : [0],
      UntPri4 : [0],
      BarCod4 : ['0'],
  
      // unit 5
      Unit5 : [0],
      Pack5 : [0],
      UntPri5 : ['0'],
      BarCod5 : ['0'],
  
      // prices
      Price1 : [0],
      Price2 : [0],
      Price3 : [0],
      Price4 : [0],
      Price5 : [0],
      Price6 : [0],

      // qtys
      qty1 : [0],
      qty2 : [0],
      qty3 : [0],
      qty4 : [0],
      qty5 : [0],
      
      // costs
      cost1 : [0],
      cost2 : [0],
      cost3 : [0],
      cost4 : [0],
      cost5 : [0],
      
      });
  }

  preItem(){
    this.ashalService.preItem().subscribe(
      preItem => {
        this.accdefs = preItem.accdefs;
        this.categorys = preItem.categorys;
        this.units = preItem.units;
      }
    );
  }

  getItem(){
    this.ashalService.getItem(this.id).subscribe(
      res => {
        this.item = res[0];
        if(this.id==0){

          this.form.patchValue({
            opeType: "save",
          });
          document.getElementById('Itm_No').focus();
        }
        else
        {
          this.form.patchValue({
            opeType: "update",
            Itm_No : res[0].Itm_No,
            Group_Code : res[0].Group_Code,
            ItmCat : res[0].ItmCat,
            Arb_Des : res[0].Arb_Des,
            Eng_Des : res[0].Eng_Des,
            DefultVendor : res[0].DefultVendor,
            StartCost : res[0].StartCost,
            AvrageCost : res[0].AvrageCost,
            LastCost : res[0].LastCost,
            OpenQty : res[0].OpenQty,
            Shipping_Cost : res[0].Shipping_Cost,
            QtyMax : res[0].QtyMax,
            ItmTyp : res[0].ItmTyp,
            Tax : res[0].Tax,
            ItmNature : res[0].ItmNature,
            QtyLvl : res[0].QtyLvl,
            ItmLoc : res[0].ItmLoc,
            Sn : Number(res[0].Sn),
            Lot : Number(res[0].Lot),
            DMY : res[0].DMY,
            Note : res[0].Note,

            DefultUnit : res[0].DefultUnit,

            Unit1 : res[0].Unit1,
            Unit2 : res[0].Unit2,
            Unit3 : res[0].Unit3,
            Unit4 : res[0].Unit4,
            Unit5 : res[0].Unit5,
            
            Pack1 : res[0].Pack1,
            Pack2 : res[0].Pack2,
            Pack3 : res[0].Pack3,
            Pack4 : res[0].Pack4,
            Pack5 : res[0].Pack5,

            UntPri1 : res[0].UntPri1,
            UntPri2 : res[0].UntPri2,
            UntPri3 : res[0].UntPri3,
            UntPri4 : res[0].UntPri4,
            UntPri5 : res[0].UntPri5,

            Price1 : res[0].Price1,
            Price2 : res[0].Price2,
            Price3 : res[0].Price3,
            Price4 : res[0].Price4,
            Price5 : res[0].Price5,
            Price6 : res[0].Price6,

            cost1 : res[0].AvrageCost * res[0].Pack1,
            cost2 : res[0].AvrageCost * res[0].Pack2,
            cost3 : res[0].AvrageCost * res[0].Pack3,
            cost4 : res[0].AvrageCost * res[0].Pack4,
            cost5 : res[0].AvrageCost * res[0].Pack5,

            qty1 : res[0].OpenQty * res[0].Pack1,
            qty2 : res[0].OpenQty * res[0].Pack2,
            qty3 : res[0].OpenQty * res[0].Pack3,
            qty4 : res[0].OpenQty * res[0].Pack4,
            qty5 : res[0].OpenQty * res[0].Pack5,

            BarCod1 : res[0].BarCod1,
            BarCod2 : res[0].BarCod2,
            BarCod3 : res[0].BarCod3,
            BarCod4 : res[0].BarCod4,
            BarCod5 : res[0].BarCod5,
            
            SeaCost : res[0].SeaCost,
            ItmPOS : res[0].ItmPOS

          });

          this.form.controls['Itm_No'].disable();

        } 

      }
    );
  }

  submit(){
    console.log(this.form.value);
    this.form.controls["Itm_No"].enable();
    this.item = this.form.value;
    this.form.controls["Itm_No"].disable();


      this.ashalService.newItem(this.item)
      .subscribe(
        res => {
          alert('تم تسجيل الصنف بنجاح \n The Item was successfully registered');
          this.form.reset();
          this.preItem();
          this.getItem();
          },
        err => console.error(err)
      )
        

  }

  chackboxo(id : number)
  {
    var st : boolean = false;
    if(id = 1)
    {
      st = true;
    }

    return st;
  }

  chackboxt(id : boolean)
  {
    console.log(id);
    var st : number = 0;
    if(id = true)
    {
      st = 1;
    }

    return st;
  }

  NewItems()
  {
    this.router.navigate(['dashboard/items-list']);
  }
  
  ChakItem(Itmno : string){
    if(this.id == 0 )
    {
    this.ashalService.getItem(Itmno).subscribe(
      item => {
        if(item.length != 0)
        {
            alert('عفواً ... رقم السجل موجود من قبل  \n Oop ... This Record is Already Exist');
            this.form.controls['Itm_No'].setValue("");
            document.getElementById('Itm_No').focus();
        }
      }
    );

    }
  }
  ngOnInit() {
    this.save = this.id == 0 ? 'save' : 'update';
    this.form.controls['OpenQty'].disable();

    this.form.controls['qty1'].disable();
    this.form.controls['qty2'].disable();
    this.form.controls['qty3'].disable();
    this.form.controls['qty4'].disable();
    this.form.controls['qty5'].disable();

    this.form.controls['cost1'].disable();
    this.form.controls['cost2'].disable();
    this.form.controls['cost3'].disable();
    this.form.controls['cost4'].disable();
    this.form.controls['cost5'].disable();
  }

}

import { Vouchers } from './vouchers';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import {Router} from "@angular/router"


import { Observable } from 'rxjs';
import { Unit } from './unit';
import { Category } from './category';
import { AccDef } from './accdef';
import { Item } from './item';
import { Voucher } from './voucher';
import { Invoice } from './invoice';
import { Invoices } from './invices';
import { infoData } from './infoData';
import { vouchersReport } from './vouchersReport';
import { user_info } from './user_info';
import { User } from './user';
import { Branch } from './Branch';


@Injectable({
  providedIn: 'root'
})
export class AshalService {
    //public apiUrl_User = 'http://ashal.test/api/';
    //public apiUrl = 'http://aglaam.net/api/';
    //public apiUrl = 'http://app.ashal-web.net/api/';
    public apiUrl_User = 'http://users.ashal-web.net/api/';
    public apiUrl = sessionStorage.getItem("urlcon");
    
  constructor(private http: HttpClient,private router: Router,) { }

  // units
  getUnits(): Observable<Unit[]>{
    return this.http
      .get<Unit[]>(sessionStorage.getItem("urlcon") + 'units-list')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  newUnit(unit: Unit): Observable<Unit>{
    return this.http.post<Unit>(sessionStorage.getItem("urlcon") + 'new-unit', unit)
  }

  viewUnit(Unit_No: string): Observable<Unit>{
    return this.http.get<Unit>(sessionStorage.getItem("urlcon") + 'unit/' + Unit_No)
  }

  updateUnit(Unit_No: string, unit: Unit): Observable<Unit>{
    return this.http.put<Unit>(sessionStorage.getItem("urlcon") + 'unit/' + Unit_No, unit)
  }

  deleteUnit(Unit_No: string): Observable<Unit>{
    return this.http.delete<Unit>(sessionStorage.getItem("urlcon") + 'unit/' + Unit_No)
  }


  // categorys


  getCategorys(): Observable<Category[]>{
    return this.http
      .get<Category[]>(sessionStorage.getItem("urlcon") + 'categorys-list')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }


  newCategory(cat: Category): Observable<Category>{
    return this.http.post<Category>(sessionStorage.getItem("urlcon") + 'new-category', cat)
  }

  viewCategory(CAT_No: string): Observable<Category>{
    return this.http.get<Category>(sessionStorage.getItem("urlcon") + 'cat/' + CAT_No)
  }

  updateCategory(CAT_No: string, cat: Category): Observable<Category>{
    return this.http.put<Category>(sessionStorage.getItem("urlcon") + 'cat/' + CAT_No, cat)
  }

  deleteCategory(CAT_No: string): Observable<Category>{
    return this.http.delete<Category>(sessionStorage.getItem("urlcon") + 'cat/' + CAT_No)
  }


  // accdef
  getAccDef(): Observable<AccDef[]>{
    return this.http
      .get<AccDef[]>(sessionStorage.getItem("urlcon") + 'accdef-list')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  getAccDefPer(): Observable<AccDef[]>{
    return this.http
      .get<AccDef[]>(sessionStorage.getItem("urlcon") + 'accdef-PerAcc')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  getAccCat(): Observable<AccDef[]>{
    return this.http
      .get<AccDef[]>(sessionStorage.getItem("urlcon") + 'accdef-AccCat')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }
 
  newAccDef(accDef: AccDef): Observable<AccDef>{
      return this.http.post<AccDef>(sessionStorage.getItem("urlcon") + 'new-accdef', accDef)
  }

  viewAccdef(AccDef_No: string): Observable<AccDef>{
    return this.http.get<AccDef>(sessionStorage.getItem("urlcon") + 'accdef/' + AccDef_No)
  }

  updateAccdef(AccDef_No: string, accDef: AccDef): Observable<Category>{
    return this.http.put<AccDef>(sessionStorage.getItem("urlcon") + 'accdef/' + AccDef_No, accDef)
  }

  deleteAccdef(AccDef_No: string): Observable<AccDef>{
    return this.http.delete<AccDef>(sessionStorage.getItem("urlcon") + 'accdef/' + AccDef_No)
  }


  // items
  preItem(): Observable<any>{
    return this.http.get(sessionStorage.getItem("urlcon") + 'pre-item')
  }

  getItems(): Observable<Item[]>{
    return this.http
      .get<Item[]>(sessionStorage.getItem("urlcon") + 'items-list')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  getItem(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'get-item', { params })
  }

  newItem(item: Item): Observable<Item>{
    return this.http.post<Item>(sessionStorage.getItem("urlcon") + 'new-item', item)
  }

  deleteItem(Itm_No: string): Observable<Item>{
    return this.http.delete<Item>(sessionStorage.getItem("urlcon") + 'item/' + Itm_No)
  }


  // vouchers
  getVouchers(): Observable<Voucher[]>{
    return this.http
      .get<Voucher[]>(sessionStorage.getItem("urlcon") + 'vouchers-list')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  newVoucher(voucher: Voucher): Observable<Voucher>{
    return this.http.post<Voucher>(sessionStorage.getItem("urlcon") + 'new-voucher', voucher)
  }

  checkGdNo(gdNo: string){
    let params = new HttpParams().set('gdNo', gdNo);
    return this.http.get(sessionStorage.getItem("urlcon") + 'check-gd-no', { params })
  }

  getAccdefBalance(accdefNo: string){
    let params = new HttpParams().set('accdefNo', accdefNo);
    return this.http.get(sessionStorage.getItem("urlcon") + 'sum-active-accdef', { params })
  }

  preVoucher(): Observable<any>{
    return this.http.get(sessionStorage.getItem("urlcon") + 'pre-voucher')
  }

  getVoucher(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'get-voucher', { params })
  }

  printVoucher(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'print-voucher', { params })
  }

  // invoices
  getInvoices(): Observable<Invoice[]>{
    return this.http
      .get<Invoice[]>(sessionStorage.getItem("urlcon") + 'invoices-list')
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  preInvoice(): Observable<any>{
    return this.http.get(sessionStorage.getItem("urlcon") + 'pre-invoice')
  }

  getInvoice(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'get-invoice', { params })
  }

  deleteInvoice(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'delete-Invoice', { params })
  }
  
  printInvoice(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'print-invoice', { params })
  }

  getItemData(Itm_No: string): Observable<any>{
    let params = new HttpParams().set('Itm_No', Itm_No);
    return this.http.get(sessionStorage.getItem("urlcon") + 'get-item-data', { params })
  }
  
  getAccDataCust(Acc_No: string): Observable<any>{
    let params = new HttpParams().set('Acc_No', Acc_No);
    return this.http.get(sessionStorage.getItem("urlcon") + 'acc_cust_data', { params })
  }

  newInvoice(invoice: Invoice): Observable<Invoice>{
    return this.http.post<Invoice>(sessionStorage.getItem("urlcon") + 'new-invoice', invoice)
  }
  
  reportInv(id: string): Observable<any>{
    let params = new HttpParams().set('id', id);
    return this.http.get(sessionStorage.getItem("urlcon") + 'report-inv', { params })
  }

  getInvoices2(fromDate,toDate): Observable<Invoices>{
    return this.http.post<Invoices>(sessionStorage.getItem("urlcon") + 'invoice',{'startDate':fromDate,'endDate':toDate} );
  }
  invReport(invID): Observable<Invoice>{
    return this.http.post<Invoice>(sessionStorage.getItem("urlcon") + 'invoice_report',{'invID':invID} );
  }


  getInvRep(fromDate,toDate): Observable<any>{
    //console.log(sessionStorage.getItem("urlcon"));
    return this.http.post(sessionStorage.getItem("urlcon") + 'pre-invrep',{'startDate':fromDate,'endDate':toDate} );
  }

  getVouchers2(fromDate,toDate,fromAcc,toAcc): Observable<Vouchers>{
    return this.http.post<Vouchers>(sessionStorage.getItem("urlcon") + 'voucher',{'startDate':fromDate,'endDate':toDate,'fromAcc':fromAcc,'toAcc':toAcc} );
  }
  getinfoData(): Observable<infoData>{
    return this.http.get(sessionStorage.getItem("urlcon") + 'info-data');
  }
  getvauchersReport(): Observable<vouchersReport>{
    return this.http.get(sessionStorage.getItem("urlcon") + 'vauchers-report');
  }

  getUsers(brn_no : string): Observable<User[]>{
    return this.http
      .get<User[]>(this.apiUrl_User + 'listuser/' + brn_no)
      // .pipe(catchError(this.handleError('getTasks', [])))
  }

  viewUser(User_No: string): Observable<User>{
    return this.http.get<User>(this.apiUrl_User + 'user/' + User_No)
  }

  updateUser(User_No: string, user: User): Observable<User>{
    return this.http.put<User>(this.apiUrl_User + 'updateuser/' + User_No, user)
  }

  checkuser(username: string,password:string): Observable<user_info>{
    return this.http.post<user_info>(this.apiUrl_User + 'checkuser', { 'username':username,'password':password })
  }

  check_is_login()
  {
    var data_user:object;
    var count = sessionStorage.length;
    var UserInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    var apikey = JSON.parse(sessionStorage.getItem('apikey'));
    if (count==0){
      this.router.navigate(['/']);
    }
    this.viewUser(UserInfo["UsrNo"]).subscribe(
      res => {
        if(res.token != UserInfo["apikey"])
        {
          this.router.navigate(['/']);
        }
      }
    );
  }

  checkbranch(branch_no: string): Observable<Branch[]>{
    return this.http.post<Branch[]>(this.apiUrl_User + 'checkbranch', { 'branch_no':branch_no })
  }

  getbranch(branch_id: number): Observable<Branch[]>{
    return this.http.post<Branch[]>(this.apiUrl_User + 'getbranch', { 'branch_id':branch_id })
  }

  is_premision(permision_key1,permision_key2,ckeckonly=false)
  {
    var UserInfo = JSON.parse(sessionStorage.getItem('UserInfo'));
    //console.log(JSON.stringify(UserInfo));
    let status = UserInfo[permision_key1][permision_key2];
    if( status == "0"){
      //alert("Access Denid"); 
      if(!ckeckonly){
        this.router.navigate(['dashboard/report']);
      }
      return false;
    }
    return true;
  }


}